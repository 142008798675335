.search-results-topbar {
	padding: 0.5%;
	background: #340f34;
	position: sticky;
	top: 10%;
	z-index: 15;
}

.selectedFlightBox {
	position: sticky;
	top: 10%;
	background: white;
	padding: 0px !important;
	opacity: 1;
	z-index: 1;
	text-align: center;
	box-shadow: 0 0 5px 5px #868585 !important;
}

.searchScroll {
	overflow-y: scroll !important;
}

.search_filter_icon {
	margin: 0.5% auto;
	width: 100%;
	background: #6a0537;
	border: none;
	text-transform: uppercase;
	font-family: cabin;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 1px;
	color: #ffffff;
	padding: 8px;
	border-radius: 4px;
	height: 60px;
	opacity: 0.9;
}

.search_filter_icon:hover {
	opacity: 1;
}

.search_header {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border-bottom: 2px dotted;
	padding: 1%;
}

.via_title {
	font-size: 21px;
	display: flex;
	justify-content: center;
	align-items: center;
	letter-spacing: 1px;
	color: #340f34;
}

/* ===================== Modal =================== */
.search-flight-modal {
	z-index: 9999;
}

.flight_details_btn {
	display: flex;
	justify-content: end;
	cursor: pointer;
	color: #340f34;
	font-weight: 600;
	letter-spacing: 0.5px;
}

/* ===================== Itineraries =================== */

.itinerary_wrapper {
	padding: 0.5% !important;
	margin-top: 0.5% !important;
}
.itineraryCol {
	margin-top: calc(10px);
}
.itinerary_container {
	margin: 0.5% 0%;
	padding: 1% 2%;
	border: 0.5px solid #b5afaf;
	transition: border 0.3s;
	-webkit-transition: border 0.23;
	-moz-transition: border 0.3s;
	-ms-transition: border 0.3s;
	box-shadow: 0 0 1px 1px #868585;
	background: #ffffff;
	/* cursor: pointer; */
}
.itinerary_container:hover {
	z-index: 1;
	border: 1px solid #340f34;
}
/* .highlightedItem {
	border: 1px solid #780878 !important;
	box-shadow: 2px 0 10px #780878 !important;
}
.topHighlightedItem {
	border: 1px solid #dc006e !important;
	 box-shadow: inset 0 0 10px #800f80 !important; 
	box-shadow: 5px 0 10px #dc006e !important;
} */
.resultHeadText {
	text-align: center !important;
	margin: 1rem 0px !important;
	color: #a0155a !important;
	font-weight: 600 !important;
	text-transform: capitalize !important;
}
.airline_details {
	color: #340f34;
	font-weight: 600;
	letter-spacing: 0.5px;
	max-width: 50% !important;
}

.airline_img {
	width: 20px !important;
	object-fit: contain;
}

.individual_constants_onward {
	background: #eee;
}

.individual_constants_return {
	background: #eee;
}

.constant_details {
	padding: 0.5%;
	display: flex;
	border: 1px solid #fff;
	height: 100px;
}

.last-item {
	border-right: none !important;
}

.constants_col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 14px;
	/* border-right: 2px dotted #fff; */
}

.text_center {
	text-align: center;
}

.text_right {
	text-align: right;
}

.org_dest_timings {
	text-transform: uppercase;
	font-weight: 600 !important;
	font-size: 21px !important;
}

.org_dest_date {
	font-size: 12px;
	font-weight: bold;
	padding: 1% 2%;
	border: 1px solid #fff;
}

.individual_fares {
	min-height: 80px;
	font-size: 14px;
	box-shadow: 2px 2px 5px 0px rgba(198, 198, 198, 1);
	-webkit-box-shadow: 2px 2px 5px 0px rgba(198, 198, 198, 1);
	-moz-box-shadow: 2px 2px 5px 0px rgba(198, 198, 198, 1);
	border: 0px dotted !important;
}

.individual_fares:hover {
	box-shadow: 3px 2px 5px 0px rgba(52, 15, 52, 1);
	-webkit-box-shadow: 3px 2px 5px 0px rgba(52, 15, 52, 1);
	-moz-box-shadow: 3px 2px 5px 0px rgba(52, 15, 52, 1);
}

.selected_card {
	border: 0.4px solid #340f34;
}

/* .selected_card:hover {
  border: 0.7px solid #340f34;
} */

.check_selected {
	visibility: visible;
	color: #409f15 !important;
	/* color: #340f34; */
}

.hide_check {
	visibility: hidden;
}

.card_class {
	display: flex;
	align-items: center;
	background: #eee;
	color: #212529;
	border-right: 0.7px dotted !important;
}

.card_price {
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 21px;
	font-weight: 600;
}

.card_price p {
	margin: 0px !important;
}

/* .card_price::after{
  position: relative;
  left: 36%;
  content: '';
  width: 25%;
  height: 35%;
  background: #9f155a;
  transform: rotate(45deg);
  z-index: 100;
} */

.card_details {
	background: #eee;
	padding: 0;
}

.fare_breakdown_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-right: 0.7px dotted !important;
	background: #eee;
	color: #212529;
	padding-top: 5px;
	padding-bottom: 5px;
}

.individual_fare_breakdowns {
	font-size: 12px;
}

.padd_0 {
	padding: 0;
}

.reset_rows {
	padding: 0;
	margin: 0;
	height: 100%;
}

.itinerarySubDetails {
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: 0.7px dotted !important;
	font-size: smaller;
}

.itinerarySubDetails:hover {
	cursor: pointer;
	background: #340f34;
	color: #fff;
}

.srn-btn {
	text-transform: capitalize !important;
	background-image: linear-gradient(96deg, #db2a5f, #a0155a) !important;
	/* background: #9f155a !important; */
	color: #fff !important;
	border-color: #9f155a !important;
	min-width: 100px !important;
	font-weight: 600 !important;
}
.srn-btn:disabled {
	text-transform: capitalize !important;
	background-image: linear-gradient(96deg, #0000005e, #00000042) !important;
	/* background: #9f155a !important; */
	color: #fff !important;
	border-color: #9f155a !important;
	min-width: 100px !important;
	font-weight: 600 !important;
}

/* .srn-btn:hover {
  background: #fff !important;
  color: #9f155a !important;
  font-weight: 600 !important;
} */

.srn-btn-fare {
	text-transform: capitalize !important;
	text-decoration: underline !important;
	color: #212529 !important;
}
.srn-btn-brandedfare {
	text-transform: capitalize !important;
	color: #212529 !important;
}

/* .srn-btn-fare:hover {
  background: #9f155a !important;
  color: #fff !important;
} */

.srn-btn-out {
	text-transform: capitalize !important;
	background: #fff !important;
	color: green !important;
	border-color: green !important;
}

.srn-btn-out:hover {
	background: green !important;
	color: #fff !important;
}

.srn-btn-hold {
	text-transform: capitalize !important;
	background: darkseagreen !important;
	color: #ffffff !important;
	/* margin: 5px 0px 0px 5px; */
}

/* .srn-btn-hold:hover {
  background: #fff !important;
  color: #9f155a !important;
} */

.selectedItenaryText {
	font-size: 18px !important;
	font-weight: 600 !important;
	margin-bottom: 0px !important;
}

.gfsPink {
	color: #9f155a !important;
}

.marginBR5 {
	margin: 0px 5px 5px 0px !important;
}

/* .fareText {
  color: #9f155a !important;
} */

.fullHeight {
	height: 100%;
}

table.fullWidth {
	width: 100%;
	margin-bottom: 3%;
	text-align: center;
}

table.fullWidth td,
table.fullWidth tr {
	border-bottom: 2px solid #fff;
}

th.top_headers {
	color: #6a0537;
}

th.left_headers {
	color: #9f155a;
}

.more_fare_details {
	margin-top: 2%;
}

.fare_details_container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.srn-txt {
	font-size: small;
}

.fare_tab_container {
	padding: 1%;
}

.fare_header_container {
	border-right: 0.7px dotted #000;
}

.fare_header_container .nav-item {
	opacity: 0.9;
}

.fare_header_container .nav-item:hover {
	border: none !important;
	opacity: 1;
}

a.nav-link {
	color: #340f34;

	-moz-transition: background-color 0.3s ease-in;
	-webkit-transition: background-color 0.3s ease-in;
	-o-transition: background-color 0.3s ease-in;
	transition: background-color 0.3s ease-in;
}

a.nav-link:hover {
	background-color: #340f34;
	color: #fff;
	-moz-transition: background-color 0.01s;
	-webkit-transition: background-color 0.01s;
	-o-transition: background-color 0.01s;
	transition: background-color 0.01s;
}

a.nav-link.active:hover {
	background: #340f34;
	color: #fff;
}

.fare_header_container .nav-item .nav-link.active {
	background: #340f34;
	color: #fff;
}

.fare_tab_contents_wrapper {
	background: #fff;
}

.fare_tabs {
	padding: 0px 5px !important;	
}

.faredtls_sh_cls {
	height: 100% !important;
	display: block !important;	
}


@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* ============== Sort Component ==================== */

.sort_component_container {
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	box-shadow: 0 0 5px 5px #868585;
}

.sort_component {
	border-radius: 4px;
	display: flex;
	background-color: #ffffff;
}

.sort_component_item {
	display: flex;
	padding: 8px;
	padding-right: 24px;
	padding-bottom: 8px;
	min-width: 100px;
}

.sort_component_item_icon {
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sort_component_item_detail {
	display: flex;
	flex-direction: column;
	margin-left: 8px;
}

.sort_component_item_title {
	font-family: "Cabin";
	font-size: 12px;
	opacity: 0.7;
}

.sort_component_item_title_sort {
	padding-left: 5% !important;
	font-family: "Cabin";
	font-size: 12px;
	opacity: 0.7;
}

.sort_component_item_value {
	font-family: "Cabin";
	font-weight: bold;
	font-size: 16px;
	margin-top: 4px;
}

.opacity-3 {
	opacity: 0.3;
}

.highlighted {
	border-bottom: 3px solid #db2a5f;
}

.pointer {
	cursor: pointer;
}

.filled-icon {
	color: #db2a5f;
}

.br-sort {
	border-right: 0.7px solid rgba(0, 0, 0, 0.1);
}

.sort-filter1 {
	/* display: flex;
  align-items: center; */
	padding: 10px !important;
	background: #fff !important;
}

.mr-8 {
	margin-right: 8px;
}

/* ======================== Flight Details Modal ========================= */

.drawer-flight-details-container {
	padding: 1.5%;
	margin-left: 24px;
	margin-right: 24px;
	margin-bottom: 24px;
}

.drawer-departure-header {
	display: flex;
	margin-bottom: 12px;
}

.drawer-departure-text {
	margin-left: 12px;
	font-size: 17px;
	font-weight: bold;
	font-family: "Cabin";
}

.drawer-departure-content {
	margin-left: 32px;
	font-size: 13px;
	font-weight: 300;
	font-family: "Cabin";
	opacity: 0.7;
}

.mt-6 {
	margin-top: 8px;
}

.mt-16 {
	margin-top: 16px;
}

.drawer-airport-name {
	margin-left: 32px;
	font-size: 13px;
	font-weight: 300;
	font-family: "Cabin";
	margin-top: -8px;
	margin-bottom: 16px;
}

.mb-16 {
	margin-bottom: 16px;
}

.mb-32 {
	margin-bottom: 32px;
}

.flight-detail-border-bottom {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mt-24 {
	margin-top: 24px;
}

.mb-24 {
	margin-bottom: 24px;
}

.opacity-6 {
	opacity: 0.6;
}

.font-size-12 {
	font-size: 12px;
}

.layover-flight-details {
	font-family: "Cabin";
	font-size: 14px;
	opacity: 0.7;
	padding-bottom: 24px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 24px;
	padding-left: 32px;
}

.operated-by-color {
	color: #a0155a;
	opacity: 1 !important;
}

.luggage-icons {
	fill: #000000 !important;
}

.luggage-margin {
	margin-left: 6px;
	margin-bottom: 3px;
}

.luggage-flex {
	display: flex;
	align-items: center;
}

.mt-12 {
	margin-top: 12px;
}





.flt-highlight-badge {
	color: #212529;
	font-size: 10px;
	padding: 4px 7px;
	text-transform: capitalize;
	margin-left: 3px;
	text-align: end !important;
}

.popup-content {
	position: relative;
	width: 100%;
	margin: auto;
	padding: 30px;
	/* border: solid 1px #dedede; */
	border-radius: 4px;
	background-color: #fff;
	box-shadow: 1px 3px 20px 2px rgb(213 213 213 / 50%);
	animation-duration: 0.001s;
	animation-name: atNodeInserted60;
	visibility: visible;
}

@keyframes atNodeInserted60 {
	0% {
		opacity: 0.99;
	}

	100% {
		opacity: 1;
	}
}

.popup-content .close-wrap {
	margin-bottom: 10px;
}

.pp {
	overflow: auto;
	max-height: 600px;
	max-height: calc(100vh - 140px);
	color: #222;
}

.pp-body {
	padding: 10px;
}

.country-select-modal h3 {
	font-size: 24px;
	font-weight: 700;
	line-height: 24px;
	margin-bottom: 0.5rem;
	padding-bottom: 10px;
}

.warning-mode-h3 {
	font-size: 24px;
	font-weight: 700;
	line-height: 24px;
	margin-bottom: 0.5rem;
	padding-bottom: 10px;
	color: red !important;
}

.warning-mode-h4 {
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	margin-bottom: 0.5rem;
	padding-bottom: 10px;
}

.country-select-modal p {
	font-size: 15px;
	margin-top: 10px;
	color: #444;
	margin-bottom: 1rem;
	font-weight: 500;
}

.country-select-modal span {
	font-size: 15px;
	padding-bottom: 10px;
	color: #666;
	margin-top: 0;
	margin-bottom: 1rem;
}

.country-wrapper {
	position: relative;
	margin-bottom: 15px;
}

.country-wrapper .MuiFormControl-fullWidth {
	font-size: 18px;
	line-height: 45px;
	position: relative;
	display: block;
	width: 100%;
	height: 50px;
	margin: 0;
	padding: 0.375rem 0.75rem;
	padding-left: 4px;
}

.pp .row {
	margin: 0;
}

@media (min-width: 768px) {
	.justify-content-md-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}

	.col-md-auto {
		width: auto;
		max-width: none;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}
}

.btn-md-dark {
	line-height: 50px !important;
	min-width: 160px !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	width: 135px !important;
	height: 50px !important;
	color: #fff !important;
	border: 0 !important;
	padding-right: 0 !important;
	padding-left: 0 !important;
	border-radius: 5px !important;
	background-image: linear-gradient(96deg, #db2a5f, #a0155a) !important;
}

.pp {
	overflow: auto;
	max-height: 600px;
	max-height: calc(100vh - 140px);
	color: #222;
}

.dialog-container .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
	-webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 4px;
	box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
		0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
	margin: 25%;
	position: relative;
	overflow-y: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	max-height: calc(100% - 64px);
	max-width: 600px;
}

.dialog-container .MuiDialog-paperWidthSm {
	max-width: 750px !important;
}

/* .dialog-container .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  color: #dc3545;
} */

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
	max-width: 600px !important;
}

.MuiDialog-paperWidthSm {
	max-width: 750px !important;
}

.fareDetailsHeadCell p {
	margin: 0px !important;
}

.fareDetailsHeadCell {
	padding: 5px !important;
	background-color: #dedede66 !important;
	color: #9f155a !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	opacity: 1 !important;
	text-align: center !important;
	text-transform: capitalize !important;
}
.fareDetailsBodyCell p {
	margin: 0px !important;
}

.fareDetailsBodyCell {
	padding: 5px !important;
	background-color: #fff !important;
	color: #9f155a !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	opacity: 1 !important;
	text-align: center !important;
	text-transform: capitalize !important;
}

.fareDetailsBodyCell {
	font-size: 14px !important;
	text-transform: capitalize !important;
}

.fareDetailsDiv {
	min-height: 300px !important;
	max-height: 310px !important;
	overflow-y: auto !important;
}

.flt-plusDisplayTxt .flt-redText {
	color: #a0155a;
	font-size: 10px;
	line-height: 9px;
	text-transform: capitalize !important;
	letter-spacing: 0.3px;
}
.contentCopyIcon {
	font-size: 15px !important;
	margin: 5px 10px 5px 0px !important;
}

.searchBar {
	display: flex;
	align-items: center;
	margin-bottom: 10px !important;
}
.searchIcon {
	font-size: 20px !important;
	margin: 0px 5px !important;
}
.searchField .MuiInputLabel-root {
	font-size: 12px !important;
}

.airportChangeAlert b {
	font-size: 14px !important;
}
.airportChangeAlert-top-onward {
	flex-direction: row !important;
	box-sizing: border-box !important;
	display: flex !important;
	place-content: flex-start space-between !important;
	align-items: flex-start !important;
	width: 100% !important;
	margin: 0 auto 30px !important;
	max-width: 480px !important;
	padding-top: 32px !important;
	position: relative !important;
}
.airportChangeAlert-top-return {
	flex-direction: row !important;
	box-sizing: border-box !important;
	display: flex !important;
	place-content: flex-start space-between !important;
	align-items: flex-start !important;
	width: 100% !important;
	margin: 0 auto 30px !important;
	max-width: 480px !important;
	padding-top: 32px !important;
	position: relative !important;
}

.airportChangeAlert-left h4 {
	color: #000 !important;
	font-size: 17px !important;
	font-weight: 500 !important;
	margin: 15px 0 0 !important;
	text-align: left !important;
	text-overflow: clip !important;
	white-space: nowrap !important;
}

.airportChangeAlert-right h4 {
	color: #000 !important;
	font-size: 17px !important;
	font-weight: 500 !important;
	margin: 15px 0 0 !important;
	text-align: right !important;
	text-overflow: clip !important;
	white-space: nowrap !important;
}
.airportChangeAlert-left {
	background: #fff;
	text-align: left;
	/* width: 100%; */
}

.airportChangeAlert-right {
	background: #fff;
	text-align: right;
	/* width: 100%; */
}
.airportChangeAlert-top-oneway::after {
	background: url(../../assets/sector-change-bg.png) center top no-repeat !important;
	width: 312px !important;
	height: 45px !important;
	position: absolute !important;
	/* top: 0 !important; */
	margin: 0 auto !important;
	content: "" !important;
	left: 0 !important;
	right: 0 !important;
}

.airportChangeAlert-top-oneway {
	display: flex;
	flex-wrap: wrap;	
	justify-content: space-between;
	/* position: relative; */
}
.airportChangeAlert-top-return {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.airportChangeAlert-top-return::after {
	background: url(../../assets/sector-change-bg-return.png) center top no-repeat !important;
	width: 312px !important;
	height: 140px !important;
	position: absolute !important;
	top: -1vw !important;
	margin: 0 auto !important;
	content: "" !important;
	left: 0 !important;
	right: 0 !important;
}
.airportChangeAlert-title {
	color: #000 !important;
	font-size: 23px !important;
	font-weight: 700 !important;
	text-transform: capitalize !important;
	margin: 0 0 25px !important;
}
.airportChangeAlert-footer {
	flex-direction: row !important;
	box-sizing: border-box !important;
	display: flex !important;
	align-items: flex-start !important;
	justify-content: space-evenly !important;
}
.srn-btn1 {
	background-color: gainsboro !important;
	color: #000000d4 !important;
	min-width: 100px !important;
	font-weight: 600 !important;
	text-transform: capitalize !important;
}
.airportChangeAlert-content {
	text-transform: capitalize !important;
	margin-top: 2vh !important;
}
.eqptype {
	text-transform: capitalize !important;
	font-weight: bolder;
}
.flight-confirmation-details-item-Brandedfare {
	display: flex !important;
	flex-direction: column !important;
}
