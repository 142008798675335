.table-cell-title {
    /* font-weight: 500; */
    font-family: "Cabin" !important;
    opacity: 0.7 !important;
    color: #000000 !important;
  }
  .table-cell-description {
    font-family: "Cabin" !important;
    opacity: 0.4 !important;
    font-size: 12px;
    color: #000000 !important;
    margin-top: 2px;
     /* max-width:180px  !important;  */
  }
  .table-background {
    background-color: #ffffff !important;
  }
  .table-paper-styles {
    border-radius: 14px !important;
    background: #ffffff;
    box-shadow: 0px 0px 5px 3px #0000000a !important;
  }
  
.pointer {
  cursor: pointer;
}
.table-loading {
  height: 64px;
  display: flex;
  align-items: center;
  margin: 16px;
}
.search-category-listing .MuiTableCell-head {
  font-weight: bold !important;
  font-size: 13px !important;
  opacity: 1;
}

.search-category-listing .MuiOutlinedInput-root {
  height: 30px;
  width: 100%;
  margin-top: 6px;
}
