.drawer-flight-details-container {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
}

.drawer-departure-header {
  display: flex;
  margin-bottom: 12px;
}

.drawer-departure-text {
  margin-left: 12px;
  font-size: 17px;
  font-weight: bold;
  font-family: "Cabin";
}

.drawer-departure-content {
  margin-left: 32px;
  font-size: 13px;
  font-weight: 300;
  font-family: "Cabin";
  opacity: 0.7;
}

.mt-6 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.drawer-airport-name {
  margin-left: 32px;
  font-size: 13px;
  font-weight: 300;
  font-family: "Cabin";
  margin-top: -8px;
  margin-bottom: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-32 {
  margin-bottom: 32px;
}

.flight-detail-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.opacity-6 {
  opacity: 0.6;
}

.font-size-12 {
  font-size: 12px;
}

.layover-flight-details {
  font-family: "Cabin";
  font-size: 14px;
  opacity: 0.7;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  padding-left: 32px;
}

.operated-by-color {
  color: #a0155a;
  opacity: 1 !important;
}

.luggage-icons {
  fill: #000000 !important;
}

.luggage-margin {
  margin-left: 6px;
  margin-bottom: 3px;
}

.luggage-flex {
  display: flex;
  align-items: center;
}

.mt-12 {
  margin-top: 12px;
}

/*********** Below Code for New Flight Details Desing ************/
.flt-plusDisplayTxt .flt-redText {
  color: #a0155a;
  font-size: 10px;
  line-height: 9px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.flt-dtls-section {
  border-top: 1px solid #cccccc;
  box-shadow: 0 1px 5px #dddddd inset;
  margin: 0px;
  border-radius: 2px;
  cursor: default;
}

.flt-itinerary-section {
  min-height: 100px;
  padding: 20px;
}

.flt-heading-bg {
  background-color: #f2f2f2;
  height: 35px;
  border-radius: 10px;
  padding-top: 8px;
}

/* .flt-main-heading {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #000000;
}

.flt-main-heading small {
  font-size: 12px;
  color: #666161;
} */

/* .flt-highlight-badge{
  background-color: #a0155a;
  color: #ffffff !important;
  font-size: 11px;
  padding: 4px 7px;
  text-transform: uppercase;
  margin-left: 3px;
} */

.flt-highlight-label {
  background-color: #fdeaea;
  border: 1px solid #f7dbdb;
  padding: 1px 4px 8px;
  border-radius: 4px;
}

.flt-highlight-label .flt-imp-badge-label {
  background-color: #bf0b0b;
  color: #ffffff;
  font-size: 11px;
  padding: 4px 7px;
  text-transform: uppercase;
}

.flt-highlight-label .flt-imp-msg {
  font-size: 13px;
  color: #c05353;
}

.flt-information-panel .flt-ic {
  width: 25px;
  height: 25px;
}

.flt-information-panel .flt-name {
  font-size: 13px;
}

.flt-information-panel .flt-number,
.flt-information-panel .flt-route-cls {
  font-size: 12px;
  color: #999999;
}

.flt-information-panel .flt-operated-by {
  font-size: 12px;
  color: #999999;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.25em;
}

.flt-information-panel .flt-departure-info {
  text-align: right;
  line-height: 16px;
  letter-spacing: 0.3px;
}

.flt-information-panel .flt-arrival-info {
  text-align: left;
  line-height: 16px;
  letter-spacing: 0.3px;
}

.flt-information-panel .flt-departure-info .flt-origin {
  padding-right: 5px;
}

.flt-information-panel .flt-arrival-info .flt-destination {
  padding-left: 5px;
}

.flt-information-panel .flt-departure-info .flt-airport-code,
.flt-information-panel .flt-arrival-info .flt-airport-code {
  font-size: 17px;
}

.flt-information-panel .flt-departure-info .flt-time,
.flt-information-panel .flt-arrival-info .flt-time {
  font-size: 17px;
  font-weight: bold;
}

.flt-information-panel .flt-departure-info .flt-date,
.flt-information-panel .flt-arrival-info .flt-date {
  font-size: 11px;
}

.flt-information-panel .flt-departure-info .flt-airport-nm,
.flt-information-panel .flt-arrival-info .flt-airport-nm {
  font-size: 11px;
  color: #999999;
}

.flt-information-panel .flt-duration-info .flt-duration {
  font-size: 12px;
  color: #999999;
}

.flt-layover-info .layover-info {
  border-bottom: 1px dotted #e2e2e2;
  text-align: center;
  padding: 12px;
  height: 10px;
}

.flt-layover-info .layover-info .layover-time {
  background-color: #ffffff;
  font-size: 12px;
  color: #999999;
  position: relative;
  left: 0;
  top: -2px;
}

.flt-badge-time {
  background-color: #e1edf8;
  color: #4b5a67 !important;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  border-radius: 8px;
  left: 0;
  top: -2px;
}

.layover-badge-time {
  background-color: #fdeaea;
  color: #ce265e !important;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  border-radius: 8px;
  left: 0;
  top: -2px;
}