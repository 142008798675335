.calcellationSelectionContainer {
  /* padding: 2vw 0vw; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin:15px;
}
.view-cancel {
  padding:15px;
  height: max-content;
  width: max-content;
  border-radius: 10px;
  background-color: white;
}
.change-hover-style:hover {
  color: grey !important;
}
.cancellationDelectionRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cancellationViewContainer {
  /* padding:2px; */
  margin: 15px;
}
.refsubmit-btn {
  background: #340f2f !important;
  color: #ffffff !important;
  font-size: 14px !important;
  /* margin-left: 900px !important; */
}
.disabled-btn {
  opacity: 0.1;
  cursor: not-allowed;
  background-color: #dcdcdc;
  color: #888888;
  border: 1px solid #cccccc;
}

.add-search-if-items-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left:15px;

  width: 100%;
}

.calendar-open  .MuiPopper-root.MuiPickersPopper-root{
  z-index: 9999;
}