/* predefined styles */
.br1 {
  border-radius: 1px !important;
}

.br2 {
  border-radius: 2px !important;
}

.br3 {
  border-radius: 3px !important;
}

.br4 {
  border-radius: 4px !important;
}

.br5 {
  border-radius: 5px !important;
}

.br6 {
  border-radius: 6px !important;
}

.br7 {
  border-radius: 7px !important;
}

.br8 {
  border-radius: 8px !important;
}

.br9 {
  border-radius: 9px !important;
}

.br10 {
  border-radius: 10px !important;
}

.br11 {
  border-radius: 11px !important;
}

.br12 {
  border-radius: 12px !important;
}

.br13 {
  border-radius: 13px !important;
}

.br14 {
  border-radius: 14px !important;
}

.br15 {
  border-radius: 15px !important;
}

.br16 {
  border-radius: 16px !important;
}

.br17 {
  border-radius: 17px !important;
}

.br18 {
  border-radius: 18px !important;
}

.br19 {
  border-radius: 19px !important;
}

.br20 {
  border-radius: 20px !important;
}

.br21 {
  border-radius: 21px !important;
}

.br22 {
  border-radius: 22px !important;
}

.br23 {
  border-radius: 23px !important;
}

.br24 {
  border-radius: 24px !important;
}

.br25 {
  border-radius: 25px !important;
}

.br26 {
  border-radius: 26px !important;
}

.br27 {
  border-radius: 27px !important;
}

.br28 {
  border-radius: 28px !important;
}

.br29 {
  border-radius: 29px !important;
}

.br30 {
  border-radius: 30px !important;
}

.br31 {
  border-radius: 31px !important;
}

.br32 {
  border-radius: 32px !important;
}

.br33 {
  border-radius: 33px !important;
}

.br34 {
  border-radius: 34px !important;
}

.br35 {
  border-radius: 35px !important;
}

.br36 {
  border-radius: 36px !important;
}

.br37 {
  border-radius: 37px !important;
}

.br38 {
  border-radius: 38px !important;
}

.br39 {
  border-radius: 39px !important;
}

.br40 {
  border-radius: 40px !important;
}

.fs1 {
  font-size: 0.0625em !important;
}

.fs2 {
  font-size: 0.125em !important;
}

.fs3 {
  font-size: 0.1875em !important;
}

.fs4 {
  font-size: 0.25em !important;
}

.fs5 {
  font-size: 0.3125em !important;
}

.fs6 {
  font-size: 0.375em !important;
}

.fs7 {
  font-size: 0.4375em !important;
}

.fs8 {
  font-size: 0.5em !important;
}

.fs9 {
  font-size: 0.5625em !important;
}

.fs10 {
  font-size: 0.625em !important;
}

.fs11 {
  font-size: 0.6875em !important;
}

.fs12 {
  font-size: 0.75em !important;
}

.fs13 {
  font-size: 0.8125em !important;
}

.fs14 {
  font-size: 0.875em !important;
}

.fs15 {
  font-size: 0.9375em !important;
}

.fs16 {
  font-size: 1em !important;
}

.fs17 {
  font-size: 1.0625em !important;
}

.fs18 {
  font-size: 1.125em !important;
}

.fs19 {
  font-size: 1.1875em !important;
}

.fs20 {
  font-size: 1.25em !important;
}

.fs21 {
  font-size: 1.3125em !important;
}

.fs22 {
  font-size: 1.375em !important;
}

.fs23 {
  font-size: 1.4375em !important;
}

.fs24 {
  font-size: 1.5em !important;
}

.fs25 {
  font-size: 1.5625em !important;
}

.fw100 {
  font-weight: 100 !important;
}

.fw200 {
  font-weight: 200 !important;
}

.fw300 {
  font-weight: 300 !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fw800 {
  font-weight: 800 !important;
}

.fw900 {
  font-weight: 900 !important;
}

.m0 {
  margin: 0 !important;
}

.m1 {
  margin: 0.0625em !important;
}

.m2 {
  margin: 0.125em !important;
}

.m3 {
  margin: 0.1875em !important;
}

.m4 {
  margin: 0.25em !important;
}

.m5 {
  margin: 0.3125em !important;
}

.m6 {
  margin: 0.375em !important;
}

.m7 {
  margin: 0.4375em !important;
}

.m8 {
  margin: 0.5em !important;
}

.m9 {
  margin: 0.5625em !important;
}

.m10 {
  margin: 0.625em !important;
}

.m11 {
  margin: 0.6875em !important;
}

.m12 {
  margin: 0.75em !important;
}

.m13 {
  margin: 0.8125em !important;
}

.m14 {
  margin: 0.875em !important;
}

.m15 {
  margin: 0.9375em !important;
}

.m16 {
  margin: 1em !important;
}

.m17 {
  margin: 1.0625em !important;
}

.m18 {
  margin: 1.125em !important;
}

.m19 {
  margin: 1.1875em !important;
}

.m20 {
  margin: 1.25em !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt1 {
  margin-top: 0.0625em !important;
}

.mt2 {
  margin-top: 0.125em !important;
}

.mt3 {
  margin-top: 0.1875em !important;
}

.mt4 {
  margin-top: 0.25em !important;
}

.mt5 {
  margin-top: 0.3125em !important;
}

.mt6 {
  margin-top: 0.375em !important;
}

.mt7 {
  margin-top: 0.4375em !important;
}

.mt8 {
  margin-top: 0.5em !important;
}

.mt9 {
  margin-top: 0.5625em !important;
}

.mt10 {
  margin-top: 0.625em !important;
}

.mt11 {
  margin-top: 0.6875em !important;
}

.mt12 {
  margin-top: 0.75em !important;
}

.mt13 {
  margin-top: 0.8125em !important;
}

.mt14 {
  margin-top: 0.875em !important;
}

.mt15 {
  margin-top: 0.9375em !important;
}

.mt16 {
  margin-top: 1em !important;
}

.mt17 {
  margin-top: 1.0625em !important;
}

.mt18 {
  margin-top: 1.125em !important;
}

.mt19 {
  margin-top: 1.1875em !important;
}

.mt20 {
  margin-top: 1.25em !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr1 {
  margin-right: 0.0625em !important;
}

.mr2 {
  margin-right: 0.125em !important;
}

.mr3 {
  margin-right: 0.1875em !important;
}

.mr4 {
  margin-right: 0.25em !important;
}

.mr5 {
  margin-right: 0.3125em !important;
}

.mr6 {
  margin-right: 0.375em !important;
}

.mr7 {
  margin-right: 0.4375em !important;
}

.mr8 {
  margin-right: 0.5em !important;
}

.mr9 {
  margin-right: 0.5625em !important;
}

.mr10 {
  margin-right: 0.625em !important;
}

.mr11 {
  margin-right: 0.6875em !important;
}

.mr12 {
  margin-right: 0.75em !important;
}

.mr13 {
  margin-right: 0.8125em !important;
}

.mr14 {
  margin-right: 0.875em !important;
}

.mr15 {
  margin-right: 0.9375em !important;
}

.mr16 {
  margin-right: 1em !important;
}

.mr17 {
  margin-right: 1.0625em !important;
}

.mr18 {
  margin-right: 1.125em !important;
}

.mr19 {
  margin-right: 1.1875em !important;
}

.mr20 {
  margin-right: 1.25em !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb1 {
  margin-bottom: 0.0625em !important;
}

.mb2 {
  margin-bottom: 0.125em !important;
}

.mb3 {
  margin-bottom: 0.1875em !important;
}

.mb4 {
  margin-bottom: 0.25em !important;
}

.mb5 {
  margin-bottom: 0.3125em !important;
}

.mb6 {
  margin-bottom: 0.375em !important;
}

.mb7 {
  margin-bottom: 0.4375em !important;
}

.mb8 {
  margin-bottom: 0.5em !important;
}

.mb9 {
  margin-bottom: 0.5625em !important;
}

.mb10 {
  margin-bottom: 0.625em !important;
}

.mb11 {
  margin-bottom: 0.6875em !important;
}

.mb12 {
  margin-bottom: 0.75em !important;
}

.mb13 {
  margin-bottom: 0.8125em !important;
}

.mb14 {
  margin-bottom: 0.875em !important;
}

.mb15 {
  margin-bottom: 0.9375em !important;
}

.mb16 {
  margin-bottom: 1em !important;
}

.mb17 {
  margin-bottom: 1.0625em !important;
}

.mb18 {
  margin-bottom: 1.125em !important;
}

.mb19 {
  margin-bottom: 1.1875em !important;
}

.mb20 {
  margin-bottom: 1.25em !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml1 {
  margin-left: 0.0625em !important;
}

.ml2 {
  margin-left: 0.125em !important;
}

.ml3 {
  margin-left: 0.1875em !important;
}

.ml4 {
  margin-left: 0.25em !important;
}

.ml5 {
  margin-left: 0.3125em !important;
}

.ml6 {
  margin-left: 0.375em !important;
}

.ml7 {
  margin-left: 0.4375em !important;
}

.ml8 {
  margin-left: 0.5em !important;
}

.ml9 {
  margin-left: 0.5625em !important;
}

.ml10 {
  margin-left: 0.625em !important;
}

.ml11 {
  margin-left: 0.6875em !important;
}

.ml12 {
  margin-left: 0.75em !important;
}

.ml13 {
  margin-left: 0.8125em !important;
}

.ml14 {
  margin-left: 0.875em !important;
}

.ml15 {
  margin-left: 0.9375em !important;
}

.ml16 {
  margin-left: 1em !important;
}

.ml17 {
  margin-left: 1.0625em !important;
}

.ml18 {
  margin-left: 1.125em !important;
}

.ml19 {
  margin-left: 1.1875em !important;
}

.ml20 {
  margin-left: 1.25em !important;
}

.ml21 {
  margin-left: 1.3125em !important;
}

.ml22 {
  margin-left: 1.375em !important;
}

.ml23 {
  margin-left: 1.4375em !important;
}

.ml24 {
  margin-left: 1.5em !important;
}

.ml25 {
  margin-left: 1.5625em !important;
}

.mtb0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mtb1 {
  margin-top: 0.0625em !important;
  margin-bottom: 0.0625em !important;
}

.mtb2 {
  margin-top: 0.125em !important;
  margin-bottom: 0.125em !important;
}

.mtb3 {
  margin-top: 0.1875em !important;
  margin-bottom: 0.1875em !important;
}

.mtb4 {
  margin-top: 0.25em !important;
  margin-bottom: 0.25em !important;
}

.mtb5 {
  margin-top: 0.3125em !important;
  margin-bottom: 0.3125em !important;
}

.mtb6 {
  margin-top: 0.375em !important;
  margin-bottom: 0.375em !important;
}

.mtb7 {
  margin-top: 0.4375em !important;
  margin-bottom: 0.4375em !important;
}

.mtb8 {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.mtb9 {
  margin-top: 0.5625em !important;
  margin-bottom: 0.5625em !important;
}

.mtb10 {
  margin-top: 0.625em !important;
  margin-bottom: 0.625em !important;
}

.mtb11 {
  margin-top: 0.6875em !important;
  margin-bottom: 0.6875em !important;
}

.mtb12 {
  margin-top: 0.75em !important;
  margin-bottom: 0.75em !important;
}

.mtb13 {
  margin-top: 0.8125em !important;
  margin-bottom: 0.8125em !important;
}

.mtb14 {
  margin-top: 0.875em !important;
  margin-bottom: 0.875em !important;
}

.mtb15 {
  margin-top: 0.9375em !important;
  margin-bottom: 0.9375em !important;
}

.mtb16 {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.mtb17 {
  margin-top: 1.0625em !important;
  margin-bottom: 1.0625em !important;
}

.mtb18 {
  margin-top: 1.125em !important;
  margin-bottom: 1.125em !important;
}

.mtb19 {
  margin-top: 1.1875em !important;
  margin-bottom: 1.1875em !important;
}

.mtb20 {
  margin-top: 1.25em !important;
  margin-bottom: 1.25em !important;
}

.mlr0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mlr1 {
  margin-left: 0.0625em !important;
  margin-right: 0.0625em !important;
}

.mlr2 {
  margin-left: 0.125em !important;
  margin-right: 0.125em !important;
}

.mlr3 {
  margin-left: 0.1875em !important;
  margin-right: 0.1875em !important;
}

.mlr4 {
  margin-left: 0.25em !important;
  margin-right: 0.25em !important;
}

.mlr5 {
  margin-left: 0.3125em !important;
  margin-right: 0.3125em !important;
}

.mlr6 {
  margin-left: 0.375em !important;
  margin-right: 0.375em !important;
}

.mlr7 {
  margin-left: 0.4375em !important;
  margin-right: 0.4375em !important;
}

.mlr8 {
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
}

.mlr9 {
  margin-left: 0.5625em !important;
  margin-right: 0.5625em !important;
}

.mlr10 {
  margin-left: 0.625em !important;
  margin-right: 0.625em !important;
}

.mlr11 {
  margin-left: 0.6875em !important;
  margin-right: 0.6875em !important;
}

.mlr12 {
  margin-left: 0.75em !important;
  margin-right: 0.75em !important;
}

.mlr13 {
  margin-left: 0.8125em !important;
  margin-right: 0.8125em !important;
}

.mlr14 {
  margin-left: 0.875em !important;
  margin-right: 0.875em !important;
}

.mlr15 {
  margin-left: 0.9375em !important;
  margin-right: 0.9375em !important;
}

.mlr16 {
  margin-left: 1em !important;
  margin-right: 1em !important;
}

.mlr17 {
  margin-left: 1.0625em !important;
  margin-right: 1.0625em !important;
}

.mlr18 {
  margin-left: 1.125em !important;
  margin-right: 1.125em !important;
}

.mlr19 {
  margin-left: 1.1875em !important;
  margin-right: 1.1875em !important;
}

.mlr20 {
  margin-left: 1.25em !important;
  margin-right: 1.25em !important;
}

.p1 {
  padding: 0.0625em !important;
}

.p2 {
  padding: 0.125em !important;
}

.p3 {
  padding: 0.1875em !important;
}

.p4 {
  padding: 0.25em !important;
}

.p5 {
  padding: 0.3125em !important;
}

.p6 {
  padding: 0.375em !important;
}

.p7 {
  padding: 0.4375em !important;
}

.p8 {
  padding: 0.5em !important;
}

.p9 {
  padding: 0.5625em !important;
}

.p10 {
  padding: 0.625em !important;
}

.p11 {
  padding: 0.6875em !important;
}

.p12 {
  padding: 0.75em !important;
}

.p13 {
  padding: 0.8125em !important;
}

.p14 {
  padding: 0.875em !important;
}

.p15 {
  padding: 0.9375em !important;
}

.p16 {
  padding: 1em !important;
}

.p17 {
  padding: 1.0625em !important;
}

.p18 {
  padding: 1.125em !important;
}

.p19 {
  padding: 1.1875em !important;
}

.p20 {
  padding: 1.25em !important;
}

.p21 {
  padding: 1.3125em !important;
}

.p22 {
  padding: 1.375em !important;
}

.p23 {
  padding: 1.4375em !important;
}

.p24 {
  padding: 1.5em !important;
}

.p25 {
  padding: 1.5625em !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pt1 {
  padding-top: 0.0625em !important;
}

.pt2 {
  padding-top: 0.125em !important;
}

.pt3 {
  padding-top: 0.1875em !important;
}

.pt4 {
  padding-top: 0.25em !important;
}

.pt5 {
  padding-top: 0.3125em !important;
}

.pt6 {
  padding-top: 0.375em !important;
}

.pt7 {
  padding-top: 0.4375em !important;
}

.pt8 {
  padding-top: 0.5em !important;
}

.pt9 {
  padding-top: 0.5625em !important;
}

.pt10 {
  padding-top: 0.625em !important;
}

.pt11 {
  padding-top: 0.6875em !important;
}

.pt12 {
  padding-top: 0.75em !important;
}

.pt13 {
  padding-top: 0.8125em !important;
}

.pt14 {
  padding-top: 0.875em !important;
}

.pt15 {
  padding-top: 0.9375em !important;
}

.pt16 {
  padding-top: 1em !important;
}

.pt17 {
  padding-top: 1.0625em !important;
}

.pt18 {
  padding-top: 1.125em !important;
}

.pt19 {
  padding-top: 1.1875em !important;
}

.pt20 {
  padding-top: 1.25em !important;
}

.pt21 {
  padding-top: 1.3125em !important;
}

.pt22 {
  padding-top: 1.375em !important;
}

.pt23 {
  padding-top: 1.4375em !important;
}

.pt24 {
  padding-top: 1.5em !important;
}

.pt25 {
  padding-top: 1.5625em !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb1 {
  padding-bottom: 0.0625em !important;
}

.pb2 {
  padding-bottom: 0.125em !important;
}

.pb3 {
  padding-bottom: 0.1875em !important;
}

.pb4 {
  padding-bottom: 0.25em !important;
}

.pb5 {
  padding-bottom: 0.3125em !important;
}

.pb6 {
  padding-bottom: 0.375em !important;
}

.pb7 {
  padding-bottom: 0.4375em !important;
}

.pb8 {
  padding-bottom: 0.5em !important;
}

.pb9 {
  padding-bottom: 0.5625em !important;
}

.pb10 {
  padding-bottom: 0.625em !important;
}

.pb11 {
  padding-bottom: 0.6875em !important;
}

.pb12 {
  padding-bottom: 0.75em !important;
}

.pb13 {
  padding-bottom: 0.8125em !important;
}

.pb14 {
  padding-bottom: 0.875em !important;
}

.pb15 {
  padding-bottom: 0.9375em !important;
}

.pb16 {
  padding-bottom: 1em !important;
}

.pb17 {
  padding-bottom: 1.0625em !important;
}

.pb18 {
  padding-bottom: 1.125em !important;
}

.pb19 {
  padding-bottom: 1.1875em !important;
}

.pb20 {
  padding-bottom: 1.25em !important;
}

.pb21 {
  padding-bottom: 1.3125em !important;
}

.pb22 {
  padding-bottom: 1.375em !important;
}

.pb23 {
  padding-bottom: 1.4375em !important;
}

.pb24 {
  padding-bottom: 1.5em !important;
}

.pb25 {
  padding-bottom: 1.5625em !important;
}

.ptb0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ptb1 {
  padding-top: 0.0625em !important;
  padding-bottom: 0.0625em !important;
}

.ptb2 {
  padding-top: 0.125em !important;
  padding-bottom: 0.125em !important;
}

.ptb3 {
  padding-top: 0.1875em !important;
  padding-bottom: 0.1875em !important;
}

.ptb4 {
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
}

.ptb5 {
  padding-top: 0.3125em !important;
  padding-bottom: 0.3125em !important;
}

.ptb6 {
  padding-top: 0.375em !important;
  padding-bottom: 0.375em !important;
}

.ptb7 {
  padding-top: 0.4375em !important;
  padding-bottom: 0.4375em !important;
}

.ptb8 {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}

.ptb9 {
  padding-top: 0.5625em !important;
  padding-bottom: 0.5625em !important;
}

.ptb10 {
  padding-top: 0.625em !important;
  padding-bottom: 0.625em !important;
}

.ptb11 {
  padding-top: 0.6875em !important;
  padding-bottom: 0.6875em !important;
}

.ptb12 {
  padding-top: 0.75em !important;
  padding-bottom: 0.75em !important;
}

.ptb13 {
  padding-top: 0.8125em !important;
  padding-bottom: 0.8125em !important;
}

.ptb14 {
  padding-top: 0.875em !important;
  padding-bottom: 0.875em !important;
}

.ptb15 {
  padding-top: 0.9375em !important;
  padding-bottom: 0.9375em !important;
}

.ptb16 {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}

.ptb17 {
  padding-top: 1.0625em !important;
  padding-bottom: 1.0625em !important;
}

.ptb18 {
  padding-top: 1.125em !important;
  padding-bottom: 1.125em !important;
}

.ptb19 {
  padding-top: 1.1875em !important;
  padding-bottom: 1.1875em !important;
}

.ptb20 {
  padding-top: 1.25em !important;
  padding-bottom: 1.25em !important;
}

.ptb21 {
  padding-top: 1.3125em !important;
  padding-bottom: 1.3125em !important;
}

.ptb22 {
  padding-top: 1.375em !important;
  padding-bottom: 1.375em !important;
}

.ptb23 {
  padding-top: 1.4375em !important;
  padding-bottom: 1.4375em !important;
}

.ptb24 {
  padding-top: 1.5em !important;
  padding-bottom: 1.5em !important;
}

.ptb25 {
  padding-top: 1.5625em !important;
  padding-bottom: 1.5625em !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pr1 {
  padding-right: 0.0625em !important;
}

.pr2 {
  padding-right: 0.125em !important;
}

.pr3 {
  padding-right: 0.1875em !important;
}

.pr4 {
  padding-right: 0.25em !important;
}

.pr5 {
  padding-right: 0.3125em !important;
}

.pr6 {
  padding-right: 0.375em !important;
}

.pr7 {
  padding-right: 0.4375em !important;
}

.pr8 {
  padding-right: 0.5em !important;
}

.pr9 {
  padding-right: 0.5625em !important;
}

.pr10 {
  padding-right: 0.625em !important;
}

.pr11 {
  padding-right: 0.6875em !important;
}

.pr12 {
  padding-right: 0.75em !important;
}

.pr13 {
  padding-right: 0.8125em !important;
}

.pr14 {
  padding-right: 0.875em !important;
}

.pr15 {
  padding-right: 0.9375em !important;
}

.pr16 {
  padding-right: 1em !important;
}

.pr17 {
  padding-right: 1.0625em !important;
}

.pr18 {
  padding-right: 1.125em !important;
}

.pr19 {
  padding-right: 1.1875em !important;
}

.pr20 {
  padding-right: 1.25em !important;
}

.pr21 {
  padding-right: 1.3125em !important;
}

.pr22 {
  padding-right: 1.375em !important;
}

.pr23 {
  padding-right: 1.4375em !important;
}

.pr24 {
  padding-right: 1.5em !important;
}

.pr25 {
  padding-right: 1.5625em !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pl1 {
  padding-left: 0.0625em !important;
}

.pl2 {
  padding-left: 0.125em !important;
}

.pl3 {
  padding-left: 0.1875em !important;
}

.pl4 {
  padding-left: 0.25em !important;
}

.pl5 {
  padding-left: 0.3125em !important;
}

.pl6 {
  padding-left: 0.375em !important;
}

.pl7 {
  padding-left: 0.4375em !important;
}

.pl8 {
  padding-left: 0.5em !important;
}

.pl9 {
  padding-left: 0.5625em !important;
}

.pl10 {
  padding-left: 0.625em !important;
}

.pl11 {
  padding-left: 0.6875em !important;
}

.pl12 {
  padding-left: 0.75em !important;
}

.pl13 {
  padding-left: 0.8125em !important;
}

.pl14 {
  padding-left: 0.875em !important;
}

.pl15 {
  padding-left: 0.9375em !important;
}

.pl16 {
  padding-left: 1em !important;
}

.pl17 {
  padding-left: 1.0625em !important;
}

.pl18 {
  padding-left: 1.125em !important;
}

.pl19 {
  padding-left: 1.1875em !important;
}

.pl20 {
  padding-left: 1.25em !important;
}

.pl21 {
  padding-left: 1.3125em !important;
}

.pl22 {
  padding-left: 1.375em !important;
}

.pl23 {
  padding-left: 1.4375em !important;
}

.pl24 {
  padding-left: 1.5em !important;
}

.pl25 {
  padding-left: 1.5625em !important;
}

.plr0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.plr1 {
  padding-left: 0.0625em !important;
  padding-right: 0.0625em !important;
}

.plr2 {
  padding-left: 0.125em !important;
  padding-right: 0.125em !important;
}

.plr3 {
  padding-left: 0.1875em !important;
  padding-right: 0.1875em !important;
}

.plr4 {
  padding-left: 0.25em !important;
  padding-right: 0.25em !important;
}

.plr5 {
  padding-left: 0.3125em !important;
  padding-right: 0.3125em !important;
}

.plr6 {
  padding-left: 0.375em !important;
  padding-right: 0.375em !important;
}

.plr7 {
  padding-left: 0.4375em !important;
  padding-right: 0.4375em !important;
}

.plr8 {
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
}

.plr9 {
  padding-left: 0.5625em !important;
  padding-right: 0.5625em !important;
}

.plr10 {
  padding-left: 0.625em !important;
  padding-right: 0.625em !important;
}

.plr11 {
  padding-left: 0.6875em !important;
  padding-right: 0.6875em !important;
}

.plr12 {
  padding-left: 0.75em !important;
  padding-right: 0.75em !important;
}

.plr13 {
  padding-left: 0.8125em !important;
  padding-right: 0.8125em !important;
}

.plr14 {
  padding-left: 0.875em !important;
  padding-right: 0.875em !important;
}

.plr15 {
  padding-left: 0.9375em !important;
  padding-right: 0.9375em !important;
}

.plr16 {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.plr17 {
  padding-left: 1.0625em !important;
  padding-right: 1.0625em !important;
}

.plr18 {
  padding-left: 1.125em !important;
  padding-right: 1.125em !important;
}

.plr19 {
  padding-left: 1.1875em !important;
  padding-right: 1.1875em !important;
}

.plr20 {
  padding-left: 1.25em !important;
  padding-right: 1.25em !important;
}

.plr21 {
  padding-left: 1.3125em !important;
  padding-right: 1.3125em !important;
}

.plr22 {
  padding-left: 1.375em !important;
  padding-right: 1.375em !important;
}

.plr23 {
  padding-left: 1.4375em !important;
  padding-right: 1.4375em !important;
}

.plr24 {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

.plr25 {
  padding-left: 1.5625em !important;
  padding-right: 1.5625em !important;
}

.lh0 {
  line-height: 0px !important;
}

.lh1 {
  line-height: 0.0625em !important;
}

.lh2 {
  line-height: 0.125em !important;
}

.lh3 {
  line-height: 0.1875em !important;
}

.lh4 {
  line-height: 0.25em !important;
}

.lh5 {
  line-height: 0.3125em !important;
}

.lh6 {
  line-height: 0.375em !important;
}

.lh7 {
  line-height: 0.4375em !important;
}

.lh8 {
  line-height: 0.5em !important;
}

.lh9 {
  line-height: 0.5625em !important;
}

.lh10 {
  line-height: 0.625em !important;
}

.lh11 {
  line-height: 0.6875em !important;
}

.lh12 {
  line-height: 0.75em !important;
}

.lh13 {
  line-height: 0.8125em !important;
}

.lh14 {
  line-height: 0.875em !important;
}

.lh15 {
  line-height: 0.9375em !important;
}

.lh16 {
  line-height: 1em !important;
}

.lh17 {
  line-height: 1.0625em !important;
}

.lh18 {
  line-height: 1.125em !important;
}

.lh19 {
  line-height: 1.1875em !important;
}

.lh20 {
  line-height: 1.25em !important;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-3 {
  letter-spacing: 3px;
}

.letter-spacing-4 {
  letter-spacing: 4px;
}

.txtCap {
  text-transform: capitalize !important;
}

.txtupr {
  text-transform: uppercase !important;
}

.txtlwr {
  text-transform: lowercase !important;
}

.txtcntr {
  text-align: center !important;
}

.txtlft {
  text-align: left !important;
}

.txtrgt {
  text-align: right !important;
}

.txtjst {
  text-align: justify !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiSvgIcon-colorPrimary {
  color: #9f155a !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.MuiInput-underline:after {
  border: none !important;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #9f155a !important;
}

.MuiTypography-body1 {
  font-family: "Cabin" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.MuiToggleButton-root.Mui-selected {
  color: #ffffff;
}

.MuiToggleButton-root.Mui-selected {
  color: #ffffff !important;
  background-color: #9f155a !important;
  box-shadow: 0px 0px 12px 0px #00000033;
}

.p-8 {
  padding: 8px;
}

.pl-5 {
  padding: 5px !important;
}

.pl-16 {
  padding-left: 16px;
}

.pl-24 {
  padding-left: 24px;
}

.bold {
  font-weight: bold !important;
}

.small-font {
  font-size: 14px;
}

.medium-opacity {
  opacity: 0.4;
}

.br-1 {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.full-height {
  height: 100%;
}

.mb-8 {
  margin-bottom: 8px;
}

.icon-blue {
  fill: #9f155a !important;
}

.hover-fill:hover {
  background-color: rgba(0, 82, 204, 0.05);
  border-radius: 2px;
}

.max-width {
  max-width: 100vw;
}

.min-width {
  min-width: 100vw;
}

.MuiToggleButton-root {
  box-shadow: 0px 0px 3px #00000029;
}

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #9f155a !important;
}

.Selectable .DayPicker-Day {
  border-radius: 4px !important;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ) {
  background-color: #9f155a !important;
}

.DayPicker-Day {
  padding-left: 14px !important;
  padding-right: 14px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.DayPicker-Weekday {
  padding: 14px !important;
}

.DayPicker-Caption > div {
  text-align: center;
  font-size: 16px !important;
}

.DayPicker-NavBar {
  position: absolute;
  left: 0;
  right: 0;
}

.DayPicker-Day {
  border-radius: 4px !important;
}

.Range
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #f5e8ef !important;
  color: #a92c6b !important;
}

.MuiDialog-paperWidthSm {
  max-width: 100vw !important;
}

.MuiDialog-paper {
  border-radius: 8px !important;
}

.PrivateSwitchBase-root-2 {
  padding: 6px !important;
}

.PrivateSwitchBase-root-1 {
  padding: 6px !important;
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
}

.MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}

.MuiCheckbox-root {
  color: rgba(0, 0, 0, 0.2);
}

.MuiTableCell-root {
  font-family: "Cabin" !important;
  border-bottom: 0px !important;
}

.MuiTableCell-head {
  font-weight: bold !important;
  font-size: 13px !important;
  opacity: 0.5;
}

.MuiSwitch-root {
  height: 34px !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #a92c6b !important;
}

.MuiStepper-root {
  background-color: rgba(0, 0, 0, 0) !important;
  padding: 4px !important;
}

.flex {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #db2a5f !important;
}

.MuiStepLabel-label {
  color: rgba(255, 255, 255, 0.7) !important;
  font-family: "Cabin" !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: 500;
  font-family: "Cabin" !important;
}

.MuiInputBase-input {
  font-family: "Cabin" !important;
}

.MuiFilledInput-root {
  background-color: #fff !important;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #db2a5f !important;
}

.MuiList-root {
  min-width: 160px;
}

.MuiMenuItem-root {
  font-family: "Cabin" !important;
  font-size: 14px !important;
}

.MuiTableContainer-root {
  box-shadow: none !important;
}

.MuiTable-root {
  /* border-collapse: initial !important; */
  border-spacing: 0px 12px !important;
}

.MuiDrawer-paperAnchorDockedRight {
  width: auto !important;
  box-shadow: 0px 0px 5px 0px #00000036 !important;
  border-left: none !important;
}

.top-z-index {
  z-index: 2000;
}

.DayPicker-Day--disabled {
  pointer-events: none;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.MuiOutlinedInput-root {
  height: 40px;
}

.MuiInputLabel-outlined {
  transform: translate(14px, 14px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.MuiFormControl-root {
  height: auto;
}

.drawer-min-width-40 {
  min-width: 45vw;
}

.MuiPaper-elevation8 {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 9%), 0px 8px 10px 1px rgb(0 0 0 / 8%),
    0px 3px 14px 2px rgb(0 0 0 / 0%) !important;
}

.MuiFormHelperText-marginDense {
  margin-top: 0px;
}

.MuiChip-root {
  height: 24px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 65px;
  height: min-content;
}

.padR5 {
  padding-right: 5px !important;
}

.padL5 {
  padding-left: 5px !important;
}

.padT5 {
  padding-top: 5px;
}

.padB5 {
  padding-bottom: 5px;
}

.padTB5 {
  padding: 5px 0;
}

.padB8 {
  padding-bottom: 8px;
}

.padTB8 {
  padding: 8px 0;
}

.pad0 {
  padding: 0px !important;
}

.bdrbBlack {
  border-bottom: 1px #000 dotted !important;
}

.marginLR10 {
  margin: 0px 10px !important;
}

.submitButton {
  background-color: #9f155a !important;
  color: #fff !important;
}

.multiline-Ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
