.invoice-container {
  min-height: 28.7cm !important;
  max-width: 21cm !important;
  margin: 0px auto !important;
  background-color: #ffffff !important;
  
}
.invoice-header {
  width: 100% !important;
}
.invoice-header-table{
  background: linear-gradient(210deg, #9f155a,#ffffff 110%);
}
.invoice-title-container {
  height: 150px;
  /* background-color: #db2a5f; */
  width: 300px;
  position: absolute;
  right: 80px;
  top: 0px;

  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.invoice-title {
  /* padding: 32px;
  font-size: 40px;
  color: white;
  font-family: "Cabin";
  text-transform: uppercase;
  font-weight: bolder;
  text-align: end; */
  padding: 1vh;
    font-size: 30px;
    color: white;
    font-family: "Cabin";
    text-transform: uppercase;
    font-weight: bolder;
    text-align: end;
}
.invoice-details-container {
   /* position: absolute;
  right: 80px;
  top: 174px;
  width: 300px; */
  padding: 0px 1vh;
 }

.invoice-detail {
  /* display: flex;
  margin-top: 10px;
  opacity: 0.7; */
  display: flex;
  margin-top: 10px;
  opacity: 0.7;
  text-align: end;
  padding-right: 1vh;
}
.invoice-item {
  flex: 1;
  font-family: "Cabin";
}
.invoice-separator {
  flex: 0.3;
}
.invoice-org-details-container {
  display: flex;
  position: absolute;
  right: 380px;
  top: 110px;
  left: 0;
  /* justify-content: space-around; */
  font-size: 15px;
  opacity: 0.7;
  font-family: "Cabin";
}
.org-phone {
  margin-bottom: 8px;
}
.org-address-1 {
  margin-bottom: 8px;
}
.org-communication-container {
  flex: 1;
  margin-left: 80px;
}
.org-address-container {
  flex: 1;
}
.invoice-to-container {
  margin-top: 10px;
  font-weight: bold;
}
.invoice-body {
  /* margin-left: 80px;
  margin-right: 80px; */
  padding: 1vh;
  margin-bottom: 100px !important;
}
.invoice-to-item {
  margin-bottom: 8px;
}
.invoice-to-heading {
  text-transform: uppercase;
  font-family: "CABIN";
  font-weight: bold;
  font-size: 20px;
  opacity: 0.3;
  margin-bottom: 16px;
}
.invoice-to-person {
  font-size: 22px;
  font-weight: bold;
  color: #9f155a;
  margin-bottom: 8px;
}
.invoice-to-phone {
  margin-top: 24px;
  opacity: 0.7;
  font-family: "Cabin";
}
.invoice-to-email {
  opacity: 0.7;
  font-family: "Cabin";
}
.invoice-to-add1 {
  margin-top: 24px;
  opacity: 0.7;
  font-family: "Cabin";
}
.invoice-to-add2 {
  opacity: 0.7;
  font-family: "Cabin";
}
.invoice-passenger-table-header {
  height: 48px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: rgba(159, 21, 90, 0.1);
  color: rgb(159, 21, 90);
}
.invoice-passenger-table-item {
  background-color: #fafafa;
}
.invoice-header-item-font-weight {
  font-weight: 600;
}
.invoice-fare-detail-container {
  background-color: #fafafa !important;
}
.invoice-contact-details-container {
  background-color: #fafafa !important;
}
.invoice-itenary-container {
  font-size: 24px;
  padding-bottom: 16px;
}
.invoice-logo-container{
  /* padding-top: 23px;
  padding-left: 75px; */
  padding: 1vh;
    /* background: repeating-linear-gradient(45deg, black, transparent 100px); */
}
.invoice-booking-padding-top-40 {
  padding-top: 14px !important;
}
.booking-padding-top-64 {
  padding-top: 14px !important;
}
.baggage-details {
  width: 100%;
  margin-top: 24px;
}
.itenary-passenger-details {
  width: 100%;
  margin-top: 24px;
  padding: 0px 10px;
}

.itenary-container {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 32px;
  color: #340f2f;
  padding: 0px 10px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.baggage-details-container-invoice {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 10px 0px;
  margin: auto;
  display: flex;
  margin-top: 2px;
  text-align: center !important;
  /* max-width: 90%; */
} 
.baggage-detail-flex {
  flex: 1;
  display: flex;
}
.baggage-detail-item {
  display: flex;
  align-items: center;
}
.baggage-detail-icon {
  margin-right: 12px;
  margin-left: 12px;
}
.baggage-detail-text {
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
}
.baggage-detail-opacity {
  opacity: 0.5;
}



.invoice-baggage-price-label
{
  font-size: 14px !important;
  font-weight: 600;
  padding: 0px 15px;
  background-color: #fff;
}
.invoice-baggage-price-txt{
  padding: 0px 15px;
  font-size: 14px !important;
  font-weight: 600;
  background-color: #fff;    
  width: 211px !important;
}

.invoice-total-price-label
{
  font-size: 15px !important;
  font-weight: 800;
  padding: 0px 15px;
  background-color: #fff;
}
.invoice-total-price-txt{
  padding: 0px 15px;
  font-size: 15px !important;
  font-weight: 800;
  background-color: #fff;    
  width: 211px !important;
}
.customerLogo {
  height: 10% !important;
    width: 60% !important;
}
.width50{
  width: 50% !important;
}
.width100{
  width: 100% !important;
}
.itenary-passenger-table {
  width: 100%;
  border-spacing: 1px;
  text-align: center !important;
}
.itenary-passenger-table-h-64 {
  height: 48px;
  line-height: 4.7vh;
  color: #2c2c2ce8;
  padding: 5px !important;
} 
.invoice-itenary-passenger-table-header-pl-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.baggage-details-container-invoice .col-1 {
  padding: 0px !important;
}
.baggage-details-container-invoice .col-2 {
  padding: 0px !important;
}
.baggage-details-container-invoice .col-3 {
  padding: 0px !important;
}
.baggage-details-container-invoice .col-4 {
  padding: 0px !important;
}