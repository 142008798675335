@-webkit-keyframes ssc-loading {
    from {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    to {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }
  }
  @keyframes ssc-loading {
    from {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    to {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }
  }

  .ssc-line{
    display: block;
    background-color: #d6d6d6;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    width: 100%;
    height: 12px;
  }

  .ssc-line:after{
    content: "";
    -webkit-animation: ssc-loading 1.3s infinite;
            animation: ssc-loading 1.3s infinite;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    z-index: 1;
    background: linear-gradient(90deg, transparent, #E0EFFF, transparent);
  }