.landing-page-container {
  display: flex;
  min-height: 100vh;
  padding-bottom: 5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/Flight Man.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.landing-page-gradient { 
  height: 100%;
  width: 100%;

  background-image: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
}
.header-container {
  display: flex;
  flex: 0.1;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 80px;
}
.search-toolbar {
  flex: 0.9;
  width: 100%;
  margin-top: 140px;
  position: relative;
}
.logo {
  height: 64px;
}
.logo-small {
  height: 48px;
}
.search-type-placeholder {
  position: absolute;
  width: 100%;
  border-radius: 4px;
  top: -60px;
  left: 0px;
  right: 0px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-type-item-container {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px #00000017;
  width: 160px;
  height: 70px;
  border-radius: 4px;
  display: flex;
}
.search-type-item {
  width: 80px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.search-type-text {
  font-family: "Cabin";
  font-weight: "bold";
  font-size: 14px;
}
.primary {
  color: #9f155a;
}
.primary-fill {
  fill: #9f155a !important;
}
.opacity-6 {
  opacity: 0.6;
}
.f-12p {
  font-size: 12px;
}
.f-13p{
  font-size: 13px;
}
.f-14p{
  font-size: 14px;
}
.coming-soon {
  font-size: 10px;
  display: inline-block;
  text-shadow: 2px 2px 8px #a0155a;
}

.importpnr-div {
  cursor: pointer;
}

/* removed  */
