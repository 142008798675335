.trans-heading {
  color: gray;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
}



.trans-client-balance {
  display: flex;
  border-radius: 10px;
  justify-content: end;
  margin-bottom: 10px;
}

.trans-client-balance-label {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: gainsboro;
  padding: 15px;
}

.trans-client-balance-Amount {
  background-color: #9f155a;
  color: white;
  padding: 15px;
  /* border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
  display: flex;
}

.trans-head-container {
  margin-top: 10px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

/* .debit-transaction-panel .MuiFormControl-fullWidth{
    margin-top: 8px;
} */
.debit-transaction-panel .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.invalidDate {
  font-size: 14px;
  color: red;
  font-weight: 500;
  margin: 10px 0px;
  text-align: center;
}