.import-pnr-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.srn-btn {
  text-transform: capitalize !important;
  background-image: linear-gradient(96deg, #db2a5f, #a0155a) !important;
  /* background: #9f155a !important; */
  color: #fff !important;
  border-color: #9f155a !important;
  min-width: 100px !important;
  font-weight: 600 !important;
}
.srn-btn:disabled {
  text-transform: capitalize !important;
  background-image: linear-gradient(96deg, #0000005e, #00000042) !important;
  /* background: #9f155a !important; */
  color: #fff !important;
  border-color: #9f155a !important;
  min-width: 100px !important;
  font-weight: 600 !important;
}

.importbooking-search-search-container {
  /* padding: 24px; */
  padding-top: 1px;
  padding-left: 24px;
  padding-right: 10px;
  background-color: #d0cfcf35;
  /* height: calc(100vh - 140px); */
  /* overflow: scroll; */
  /* margin-bottom: 48px; */
}

.importbooking-search-table-container {
  margin-top: 10px;
  padding-bottom: 10px;
}

.search-heading {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  opacity: 0.7;
  text-transform: uppercase;
}

.importbooking-search-card-container {
  /* margin-top: 24px; */
  margin-bottom: 24px;
  /* margin-left: 24px;
    margin-right: 24px; */
}

.create-search-contents {
  flex: 0.7;
}

.add-search-if-items-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.flex-1-input {
  flex: 1;
}
.margin-right-8 {
  margin-right: 8px;
}
.margin-right-16 {
  margin-right: 16px;
}

.import-booking-itenary-container {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 24px;
  color: #340f2f;
  padding-bottom: 20px;
  padding-top: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
}

.flight-itenary-details-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flight-itenary-details-item-text {
  font-size: 18px;
  font-weight: 500;
}

.flight-itenary-details-item-subtext {
  font-size: 12px;
  font-weight: 300;
  /* opacity: 0.4; */
  margin-top: 4px;
}

.itenary-container-import {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 32px;
  color: #340f2f;
  padding: 0px 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking-padding-top-40 {
  padding-top: 40px;
}

.importBooking .itenary-passenger-table-header-item-initial {
  padding-left: 10px;
  border-top-left-radius: 8px;
  /* border-bottom-left-radius: 8px; */
}
.importBooking .itenary-passenger-table {
  width: 100%;
  border-spacing: 1px;
  text-align: left !important;
}

.create-booking-button-container {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  text-align: right;
  padding-right: 10px;
}

.bd-stl {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 32px;
  color: #340f2f;
  padding: 0px 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
