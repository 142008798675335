.import-booking-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.import-booking-itenary-container {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 24px;
  color: #340f2f;
  padding-bottom: 20px;
  padding-top: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
}

.itenary-status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itenary-status-font {
  font-size: 14px;
  font-weight: 300;
}

.itenary-container-import {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 32px;
  color: #340f2f;
  padding: 0px 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking-padding-top-40 {
  padding-top: 40px;
}

.flight-itenary-date {
  font-size: 16px;
  font-weight: 500;
}

.flight-itenary-icon {
  opacity: 0.4;
  margin-left: 6px;
  margin-right: 6px;
  height: 24px;
  width: 24px;
}

.flight-itenary-details {
  padding: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.flight-itenary-details-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flight-itenary-details-item-subtext {
  font-size: 12px;
  font-weight: 300;
  /* opacity: 0.4; */
  margin-top: 4px;
}

.flight-confirmation-details {
  margin: 16px 24px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

.flight-confirmation-details-item-text {
  font-size: 13px;
  font-weight: 500;
}

.flight-confirmation-details-item-subtext {
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  opacity: 0.6;
}

.flight-confirmation-details-item {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.full-opacity {
  opacity: 1 !important;
}

.itenary-trip-details {
  flex-direction: column;
  margin: 16px 24px;
  padding-bottom: 8px;
  display: flex;
}

.itenary-flight-details {
  flex: 1;
}

.itenary-trip-from-to-container {
  flex: 1;
  display: flex;
}

.font-size-14 {
  font-size: 14px !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.flight-itenary-to-container {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.5;
}

.itenary-to-line {
  flex: 1;
  height: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.itenary-to-text {
  margin-left: 16px;
  margin-right: 16px;
  font-size: 12px !important;
  opacity: 0.6;
  font-weight: 500;
}

.itenary-passenger-details-import {
  width: 100%;
  margin-top: 24px;
  padding: 10px;
  background-color: #fff !important;
}
.itenary-passenger-details-baggage {
  width: 100%;
  margin-top: 24px;
  padding: 0px;
}

.s-no {
  max-width: 16px;
}

.w100 {
  width: 100% !important;
}

.fare-details {
  width: 100%;
  margin-top: 24px;
}

.fare-detail-container {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 24px;
}

.fare-item-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}

.fare-item-less-opacity {
  opacity: 0.7;
}

.fare-item-container-padding {
  padding-top: 8px;
}

.import-booking-fare-item-total-padding {
  padding-top: 16px;
  padding-right: 20px;
  padding-bottom: 16px;
}

.import-booking-fare-item-total {
  font-size: 14px;
  font-weight: 700;
  text-align: right;
}

.booking-padding-top-64 {
  padding-top: 64px;
}

.contact-details {
  width: 100%;
  margin-top: 24px;
}

.contact-details-container {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 16px 24px;
  display: flex;
}

.contact-detail-flex {
  flex: 1;
  display: flex;
}

.contact-detail-item {
  display: flex;
  align-items: center;
}

.contact-detail-icon {
  margin-right: 12px;
  margin-left: 12px;
}

.contact-detail-text {
  font-size: 12px;
  line-height: 16px;
}

.contact-detail-opacity {
  opacity: 0.5;
}

.min-width-101 {
  min-width: 101px;
}

.more-details {
  width: 100%;
  margin-top: 24px;
}

.search-container {
  width: 100vw;
  height: 100%;
  /* background-color: #fffbf0; */
}

.confiration-navbar {
  height: 70px;
  background-color: #ffffff;
  box-shadow: 0px -1px 8px 2px #00000017;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
}

.nav-bar-items-container {
  display: flex;
  height: 70px;
}

.mr-24 {
  margin-right: 24px;
}

.mr-8 {
  margin-right: 8px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.nav-bar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 24px;
}

.nav-bar-item-icon {
  opacity: 0.2;
}

.nav-bar-item-text {
  font-family: "Cabin";
  font-weight: 300;
  opacity: 0.7;
}

.search-content-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.category-drawer-opener {
  position: fixed;
  top: 40vh;
  bottom: 0;
  left: -40px;
  height: 80px;
  width: 80px;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px 0px 5px 3px #0000000a;
}

.category-expand-icon-margin {
  margin-right: 8px;
}

.search-category-container {
  min-width: 360px;
}

.shadow-inner {
  height: 8px;
  box-shadow: 0px 3px 4px 1px #0000000d inset;
}

.search-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 16px;
}

.search-heading {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  opacity: 0.7;
  text-transform: uppercase;
}

.search-heading-icons {
  display: flex;
}

.search-search-container {
  padding: 24px;
  padding-top: 0px;
  padding-left: 24px;
  padding-right: 24px;
  /* height: calc(100vh - 140px); */
  /* overflow: scroll; */
  /* margin-bottom: 48px; */
}

.pr-pl-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.mt-16 {
  margin-top: 16px;
}

.search-table-container {
  margin-top: 16px;
  padding-bottom: 40px;
}

.justify-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-card-container {
  /* margin-top: 24px; */
  margin-bottom: 24px;
  /* margin-left: 24px;
    margin-right: 24px; */
}

.add-search-button {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #a0155a;
  color: #a0155a;
  border-radius: 4px;
  margin-top: 4px;
}

.add-search-button-container {
  display: inline-block;
  cursor: pointer;
}

.search-icon-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-icon-margin {
  margin-right: 24px;
  fill: #a0155a !important;
  cursor: pointer;
}

.table-loading {
  height: 64px;
  display: flex;
  align-items: center;
  margin: 16px;
}

.search-container {
  width: 100vw;
  height: 100%;
  /* background-color: #fffbf0; */
}

.search-content-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.search-category-container {
  min-width: 360px;
}

.search-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 16px;
}

.search-heading {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  opacity: 0.7;
  text-transform: uppercase;
}

.search-heading-icons {
  display: flex;
}

.importbooking-search-search-container {
  /* padding: 24px; */
  padding-top: 0px;
  padding-left: 24px;
  padding-right: 10px;
  /* height: calc(100vh - 140px); */
  /* overflow: scroll; */
  /* margin-bottom: 48px; */
}

.importbooking-button-table-padding {
  padding-top: 10px;
  text-align: center;
}

.importbooking-search-table-container {
  margin-top: 10px;
  padding-bottom: 10px;
}

.justify-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.importbooking-search-card-container {
  /* margin-top: 24px; */
  margin-bottom: 24px;
  /* margin-left: 24px;
    margin-right: 24px; */
}

.importbooking-add-search-button {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #a0155a;
  color: #a0155a;
  border-radius: 4px;
  margin-top: 4px;
}

.add-search-button-container {
  display: inline-block;
  cursor: pointer;
}

/* .table-loading {
    height: 64px;
    display: flex;
    align-items: center;
    margin: 16px;
  } */

.success {
  background-color: #006644;
}

.mco-dtls-bg {
  background-color: #ffffff;
  padding: 20px 7px;
  border-radius: 0px 0px 8px 8px;
  margin-top: 24px;
}

.mco-dtls-bg .MuiFilledInput-root {
  padding-top: 7px !important;
}

.mco-dtls-bg .MuiAutocomplete-root .MuiFilledInput-root {
  background-color: #ffffff !important;
  border: 1px solid #e6e6e6;
  height: 44px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.mco-dtls-bg .css-1xqv6w5-MuiInputBase-root-MuiFilledInput-root:hover {
  border-bottom: 1px solid #e6e6e6;
}

.mco-dtls-bg .css-1xqv6w5-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: 1px solid #e6e6e6;
}

.mco-dtls-bg .css-1xqv6w5-MuiInputBase-root-MuiFilledInput-root:before {
  border-bottom: none;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7.5px 5px !important;
}

.create-booking-button-container {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.warning-msg-txt {
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #db2a5f;
  border-left: 3px solid #db2a5f;
  border-radius: 3px;
  font-family: "Cabin" !important;
  color: #db2a5f;
  padding: 10px;
  background-color: rgba(219, 42, 95, 0.1);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .table-loading {
  height: 64px;
  display: flex;
  align-items: center;
  margin: 16px;
} */
.add-search-card-container {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 24px;
  padding-top: 24px;
  padding-bottom: 4px;
  box-shadow: 0px 0px 5px 3px #0000000a;
}

.add-search-card-title {
  font-family: "Urbanist";
  font-weight: 600;
  font-size: 16px;
}

.create-importsearch-contents-container {
  margin-top: 24px;
  margin-bottom: 24px;
  /* display: flex; */
}

.create-search-name-container {
  /* width: 50%; */
  margin-bottom: 16px;
}

.add-search-if-container {
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: "Urbanist";
  font-weight: 600;
  font-size: 14px;
}

.create-search-contents {
  flex: 0.7;
}

.create-search-image {
  flex: 0.4;
  max-height: 240px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.add-search-if-items-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 15px 0px;
}

.flex-1-input {
  flex: 1;
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-right-16 {
  margin-right: 16px;
}

.salesStaff
  .css-1qqsdnr-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-input {
  width: 0;
  min-width: 100px;
  padding: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FOP
  .css-1qqsdnr-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-input {
  width: 0;
  min-width: 100px;
  padding: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fema
  .css-1qqsdnr-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-input {
  width: 0;
  min-width: 100px;
  padding: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleteIcon {
  color: #d32f2f !important;
}

.editIcon {
  color: #9f155a !important;
}

.cabinClass
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiFilledInput-root {
  padding-right: 0px !important;
  min-width: 170px !important;
}

.cabinClass .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width: 0;
  max-width: 165px !important;
  min-width: 150px !important;
  padding: 10px 0px !important;
  text-overflow: ellipsis;
}
.importBooking .itenary-passenger-table-header-item-initial {
  padding-left: 10px;
  border-top-left-radius: 8px;
  /* border-bottom-left-radius: 8px; */
}
.importBooking .itenary-passenger-table {
  width: 100%;
  border-spacing: 1px;
  text-align: left !important;
}
.importBooking .drawer-min-width-50 {
  min-width: 50vw;
  max-width: 50vw;
}

.ImportBookingFareDetails .MuiOutlinedInput-input {
  padding: 18.5px 0px !important;
  min-width: 50px !important;
  text-align: center !important;
}
.import-booking-mco .MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;
  margin-top: -15px;
}
.import-booking-mco .MuiAutocomplete-root label {
  margin-top: -25px;
}
.import-booking-mco .mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
  align-content: baseline;
  align-items: end;
}
.import-header-item-font-weight {
  font-weight: 600 !important;
}
.import-booking-remarks .MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;
  margin-top: 0px;
}

.import-booking-remarks .MuiFormControl-root .MuiTextField-root {
  max-width: 98.5% !important;
  min-height: 100px !important;
}
.import-booking-remarks .MuiOutlinedInput-root {
  min-height: 100px !important;
}
.importTripTypeDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* align-content: center; */
  /* flex-wrap: wrap; */
  justify-content: space-between;
}
.flight-itenary-table-title-container {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  font-family: "Cabin";
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #dedede;
  padding: 10px;
}

.bagg-thead .MuiTableCell-head {
  opacity: 1 !important;
}
