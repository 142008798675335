html {
  scroll-behavior: auto;
}

.App {
  text-align: center;
  font-size: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiTabs-scroller .active {
  color: #9f155a !important;
  opacity: 1 !important;
}

.makeStyles-tab-22 {
  opacity: 1 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ffb703;
}

.searchScroll {
  overflow-y: scroll !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sessionTimer {
  text-transform: capitalize;
  color: red;
  font-size: 40px !important;
}

.textCenter {
  text-align: center !important;
}

.textEnd {
  text-align: end !important;
}

.sessionTimerred {
  text-transform: capitalize;
  font-size: 40px !important;
  color: red !important;
  animation: 2s anim-popoutin ease infinite;
}

/* @keyframes anim-popoutin {
  0% {
    color: black;
    transform: scale(0);
    opacity: 0;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  25% {
    color: red;
    transform: scale(1);
    opacity: 1;
    text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
  }
  50% {
    color: black;
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
} */
.sessionTimergreen {
  text-transform: capitalize;
  font-size: 40px !important;
  color: green !important;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.sessionActionButtons {
  flex: 0 0 auto;
  display: flex;
  padding: 8px;
  align-items: center !important;
  justify-content: center !important;
}

.posFix {
  position: fixed !important;
}

.width100 {
  width: 100% !important;
}

.detailedFareButton {
  margin-left: 25% !important;
  color: #9f155a !important;
}

.detailedFareCloseButton {
  color: #9f155a !important;
  float: right !important;
}

.detailedFareDiv {
  min-width: 65vw !important;
  /* max-height: 600px !important; */
  /* margin-top: 100px !important; */
}

.detailedFare .MuiTabs-centered {
  background-color: #fff !important;
  color: #9f155a !important;
}

.detailedFare .MuiTab-root {
  text-transform: capitalize !important;
}

.detailedFare .Mui-selected {
  background-color: #9f155a !important;
  color: #fff !important;
}

.detailedFare .MuiTabs-indicator {
  background-color: #fff !important;
}

.detailedFare .MuiTableCell-root {
  max-width: 300px !important;
}

.detailedFare .mealTotal .MuiTableCell-root {
  font-weight: 600 !important;
}

.MuiButton-containedSecondary {
  background-color: #9f155a !important;
  color: #fff !important;
}

.ticketModalContainer {
  max-height: 550px !important;
  overflow-y: auto !important;
}

.ticketModalContainer .MuiFormLabel-root.Mui-disabled {
  color: #000 !important;
}

.bdr1dot {
  border-right: 1px dotted;
}

.textTransCap {
  text-transform: capitalize !important;
}

.textElipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.bagText {
  max-width: 100px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: clip !important;
  font-size: 14px !important;
  margin-bottom: 0.5rem !important;
  line-height: 1.2 !important;
}

.color0 {
  color: #000 !important;
}

.colorRed {
  color: red !important;
}

.colorGreen {
  color: green !important;
}

.colorBlack {
  color: #000 !important;
}

.backddd47 {
  background: #dddddd47 !important;
}

.backddd82 {
  background: #dddddd82 !important;
}

.opacity1 {
  opacity: 1 !important;
}

.marginLR15 {
  margin: 0px 15% !important;
}

.currencySymbol {
  padding: 0px 5px !important;
  float: left !important;
}

.mr10 {
  margin-right: 10px !important;
}

.fs10 {
  font-size: 10px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs16 {
  font-size: 16px !important;
}

.m0 {
  margin: 0px !important;
}

.linkcolor {
  /* color: #0d6efd !important;
  color: #a0155a !important; */
  color: #db2a5f !important;
  cursor: pointer !important;
}

.G2IRetailQR {
  width: 5vw !important;
  object-fit: scale-down !important;
}

.pad1vw {
  padding: 1vw !important;
}

.padLR2vw {
  padding: 0 2vw;
}

.padTB1vw {
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.padLR1vw {
  padding: 0 1vw;
}

.padR2vw {
  padding-right: 2vw;
}

.fontMono {
  font-family: monospace !important;
}

.fontSansSerif {
  font-family: sans-serif !important;
}

.fontCabin {
  font-family: "Cabin" !important;
}

.fontCabinBold {
  font-family: "CabinBold" !important;
}

.floatInEnd {
  float: inline-end !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.Failure {
  color: red !important;
}

.Aborted {
  color: red !important;
}

.Invalid {
  color: red !important;
}

.Timeout {
  color: red !important;
}

.Success {
  color: green !important;
}

.Pending {
  color: orange !important;
}

.walletTopupAmountText .currencySymbol {
  padding: 0px 5px !important;
  float: none !important;
}

.floatL {
  float: left !important;
}

.orderTracePicker {
  max-width: 20vw !important;
}

.col-4 {
  flex: 0 0 auto !important;
  width: 33.33333333% !important;
}

.dispFlex {
  display: flex;
}

.alignitemsCenter {
  align-items: center;
}

.parseItineraryBtn {
  float: left !important;
  margin-left: 10px !important;
  color: #0a3622 !important;
  border-color: #0a3622 !important;
}

.parseItineraryBtn:disabled {
  float: left !important;
  margin-left: 10px !important;
  color: #787878 !important;
  border-color: #787878 !important;
}

.pg-charge-bold .currencySymbol {
  float: none !important;
}

.MuiAccordionSummary-root {
  min-width: 100% !important;
}

.MuiAccordionSummary-gutters {
  min-width: 100% !important;
}

.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
  min-width: 100% !important;
}

.MuiButtonBase-root
  .MuiAccordionSummary-root
  .MuiAccordionSummary-gutters
  .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
  min-width: 100% !important;
}

.custom-editor {
  width: 600px;
  height: 300px;
  overflow: auto;
  background-color: #0d0d0d;
  color: #02e3ab;
  font-size: 0.9rem;
  padding: 1rem;
}

.custom-editor p,
.custom-editor code {
  color: #02e3ab;
  font-size: 0.9rem;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 20px !important;
  height: 20px !important;
  padding: 0;
  cursor: pointer;
  background-color: rgba(165, 26, 91, 0.46) !important;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.carousel-indicators {
  justify-content: flex-start !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 5% !important;
}

.carousel-indicators .active {
  background-color: rgba(165, 26, 91, 1) !important;
}

.responsive-container {
  border-radius: 15px;
  padding: 1rem;
  height: 100%;
}

.responsive-container .responsive-img {
  width: 50px;
}

.responsive-container .content {
  width: calc(100% - 50px);
}

.responsive-container .responsive-img img {
  width: 100%;
}

.responsive-container .content {
  padding-left: 1rem;
}

.registerNowButton {
  text-transform: none !important;
  background-color: rgb(216, 42, 97) !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 76px !important;
  padding: 0.8em 4em !important;
  font-family: Kanit !important;
  font-size: 1rem !important;
}

.pressenceContainer {
  padding: 4rem 0;
  background: white;
}

.travelSolutionsContainer {
  background-color: rgba(159, 21, 90, 0.05);
  font-size: 2vw;
}

.products {
  flex-wrap: wrap;
  margin-bottom: -1rem;
}

.products .products-item {
  margin-bottom: 1rem;
}

.companyLogo {
  height: 5rem;
}

.signinButtonContainer {
  display: flex;
}

.sign-in-section {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: flex-start;
}

@media (max-width: 1300px) {
  .sign-in-section {
    flex-direction: column;
  }

  .signinButtonContainer {
    margin-top: 2rem;
  }
}

@media (max-width: 991px) {
  .companyLogo {
    height: 4rem;
  }
}

@media (max-width: 767px) {
  .registerNowButton {
    padding: 0.8em 3em !important;
  }
}

@media (max-width: 600px) {
  .sign-in-section {
    display: block;
    width: 90%;
    margin: auto;
  }
  .signinButtonContainer {
    margin-top: 3rem;
  }
}

/* responsive css start */
@media (max-width: 400px) {
  .TopText {
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    background-color: #d82a61;
    padding: 10px;
    color: #ffffff;
    background-color: #d82a61;
  }

  .HomeRegister {
    text-align: center;
  }

  .HomeRegister h1 {
    font-size: 1rem;
  }

  .carousel360 {
    height: 12rem !important;
  }

  .para320 {
    font-size: 1rem !important;
  }

  .text-bestsolution {
    text-align: center;
  }

  .text-bestsolution h1 {
    font-size: 1.3rem !important;
  }

  .text-bestsolution p {
    font-size: 1rem !important;
  }

  .Icon-imged {
    width: 3em !important;
  }

  .pressenceContainer h1 {
    font-size: 1.3rem !important;
  }

  .text-left {
    border-radius: 67px !important;
  }

  .responsive-text {
    font-size: 12px !important;
  }

  .padding320 {
    padding: 0.25em 0.25em 0 0 !important;
  }

  .local-responsive-img {
    width: 10rem !important;
  }

  .languageSwitcher {
    right: 5vw;
    bottom: 2vh;
  }

  .bestSolutions {
    h5 {
      color: rgb(0, 0, 0);
      font-size: 1rem;
      font-family: Kanit;
      line-height: 1;
      font-weight: 600 !important;
      margin: 0 10px;
    }

    p {
      color: rgb(133, 133, 133);
      font-family: Kanit;
      line-height: 1;
      margin: 0 10px;
      font-size: 0.8rem;
      padding-top: 5px;
    }
  }

  .languageSwitcherFab {
    right: 20vw;
    bottom: 2vh;
  }

  .products {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .pressenceContainer h5 {
    font-size: 5vw !important;
  }

  .products {
    padding: 1em 0 0 !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;  */
    gap: 20px !important;
    padding: 1em 0 0 !important;
  }

  .get2ItalyBanner {
    /* width: 100% !important;
    height: 100% !important;
    object-fit: scale-down !important; */
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .get2ItalyBannerWrapper img {
    object-fit: scale-down;
    /* Ensures placeholder matches the final image */
    width: 90vw !important;
    height: 100% !important;
    transition: all 0.3s ease-in-out;
    /* Smooth transition */
  }

  .get2ItalyBannerWrapper {
    overflow: hidden;
    /* Prevent any overflow issues */
    position: relative;
    width: 90vw !important;
    height: 100% !important;
    object-fit: scale-down !important;
  }

  .travelSolutionsContainer {
    padding: 10vh 5vw;

    text-align: left;
  }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100% !important;

    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }

  .footer-content {
    margin-left: 0px !important;
  }

  .footer-content-item-320 {
    display: flex;
    justify-content: center !important;
    gap: 10px;
  }

  .footer-content-item-img1 {
    width: 4rem !important;
  }

  .footer-content-item-img2 {
    width: 5rem !important;
  }

  .footer-content-logo-320 {
    width: 50%;
  }

  .footer-content-576 {
    text-align: center;
  }

  .footer-content-items {
    margin-top: 20px !important;
  }

  .responsiveContainer576 {
    max-width: 80% !important;
  }

  .responsiveContainerWhite576 {
    width: 80% !important;
  }

  .login320 {
    width: 258px !important;
  }
  .loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    padding: 15px 0;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .languageSwitcher {
    right: 5vw;
    bottom: 2vh;
  }

  .login576 {
    width: 50% !important;
  }

  .responsiveContainer576 {
    max-width: 80% !important;
  }

  .responsiveContainerWhite576 {
    width: 80% !important;
  }

  .loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    padding-top: 0.8rem;
  }

  .HomeRegister {
    text-align: center;
  }

  .para576 {
    font-size: 1.1rem !important;
  }

  .TopText {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    background-color: #d82a61;
    padding: 10px;
    color: #ffffff;
    background-color: #d82a61;
  }

  .bestSolutions {
    h5 {
      color: rgb(0, 0, 0);
      font-size: 1.2rem;
      font-family: Kanit;
      line-height: 2.6vw;
      font-weight: 400 !important;
      margin: 0 10px;
    }

    p {
      color: rgb(133, 133, 133);
      font-family: Kanit;
      line-height: 1;
      margin: 0 10px;
      font-size: 0.8rem;
      padding-top: 0.2rem;
    }
  }

  .languageSwitcherFab {
    right: 20vw;
    bottom: 2vh;
  }

  .products {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .pressenceContainer h5 {
    font-size: 5vw !important;
  }

  .products {
    padding: 1em 0 0 !important;
    display: flex;
    justify-content: center;
    /* display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important; */
    gap: 10px !important;
    padding: 1em 0 0 !important;
  }

  .text-bestsolution {
    text-align: center;
  }

  .text-bestsolution h1 {
    font-size: 1.6rem !important;
  }

  .Icon-imged {
    width: 2em !important;
  }

  .get2ItalyBanner {
    /* width: 100vw !important;
    height: 100% !important;
    object-fit: scale-down !important; */
    object-fit: cover !important;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .get2ItalyBannerWrapper img {
    object-fit: scale-down;
    /* Ensures placeholder matches the final image */
    width: 100vw !important;
    height: 100% !important;
    transition: all 0.3s ease-in-out;
    /* Smooth transition */
  }

  .get2ItalyBannerWrapper {
    overflow: hidden;
    /* Prevent any overflow issues */
    position: relative;
    width: 90vw !important;
    height: 100% !important;
    object-fit: scale-down !important;
  }

  .travelSolutionsContainer {
    padding: 10vh 5vw;

    text-align: left;
  }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100% !important;
    /* height: 20vh !important; */
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }

  .footer-content {
    margin-left: 0px !important;
  }

  .footer-content-item-576 {
    display: flex;
    justify-content: center !important;
  }

  .footer-content-logo-576 {
    width: 75%;
  }

  .footer-content-576 {
    text-align: center;
  }

  .TopText576 {
    padding: 10px !important;
  }

  .bestTravel576 {
    max-width: 85% !important;
  }
}

@media (min-width: 600px) and (max-width: 769px) {
  .languageSwitcher {
    right: 5vw;
    bottom: 2vh;
  }

  .responsiveContainer768 {
    max-width: 80% !important;
  }

  .responsiveContainerWhite768 {
    width: 80% !important;
  }

  .TopText {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    background-color: #d82a61;
    padding: 10px;
    color: #ffffff;
    background-color: #d82a61;
  }

  .bestSolutions {
    h5 {
      color: rgb(0, 0, 0);
      font-size: 1.4rem;
      font-family: Kanit;
      line-height: 0vw;
      font-weight: 400 !important;
      margin: 0 10px;
    }

    p {
      color: rgb(133, 133, 133);
      font-family: Kanit;
      line-height: 1;
      margin: 10px;
      font-size: 1rem;
    }
  }

  .languageSwitcherFab {
    right: 7vw;
    bottom: 2vh;
  }

  .pressenceContainer h5 {
    font-size: 5vw !important;
  }

  .products {
    padding: 1em 0 0 !important;
    display: flex;

    justify-content: center;
    /* display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important; */
    gap: 10px !important;
    padding: 1em 0 0 !important;
  }

  .get2ItalyBanner {
    /* width: 90vw !important;
    height: 100% !important;
    object-fit: scale-down !important; */
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .get2ItalyBannerWrapper img {
    object-fit: scale-down;
    /* Ensures placeholder matches the final image */
    width: 90vw !important;
    height: 100% !important;
    transition: all 0.3s ease-in-out;
    /* Smooth transition */
  }

  .get2ItalyBannerWrapper {
    overflow: hidden;
    /* Prevent any overflow issues */
    position: relative;
    width: 90vw !important;
    height: 100% !important;
    object-fit: scale-down !important;
  }

  .travelSolutionsContainer {
    padding: 10vh 5vw;

    text-align: left;
  }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100% !important;

    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .languageSwitcher {
    right: 5vw;
    bottom: 2vh;
  }

  .responsiveContainer768 {
    max-width: 80% !important;
  }

  .responsiveContainerWhite768 {
    width: 80% !important;
  }

  .footer-content-lastone {
    margin-left: 70px !important;
  }

  .Icon-imged {
    width: 1.5rem !important;
  }

  .TopText {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    background-color: #d82a61;
    padding: 10px;
    color: #ffffff;
    background-color: #d82a61;
  }

  .bestSolutions {
    h5 {
      color: rgb(0, 0, 0);
      font-size: 1.2rem;
      font-family: Kanit;
      line-height: 0vw;
      font-weight: 400 !important;
      margin: 0 10px;
      padding-top: 1rem;
    }

    p {
      color: rgb(133, 133, 133);
      font-family: Kanit;
      line-height: 1;
      margin: 10px;
      font-size: 0.8rem;
    }
  }

  .languageSwitcherFab {
    right: 15vw;
    bottom: 2vh;
  }

  .loginContainer {
    max-width: 100%;
    display: flex;
    justify-content: space-around;
    background: rgb(249, 239, 244);
    padding: 1em 0em 1.5em !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }

  .pressenceContainer h5 {
    font-size: 5vw !important;
  }

  /* .products {
    display: flex;
    padding: 1em 0 0;
  } */

  /* .registerNowButton {
    width: 40vw !important;
  } */

  .get2ItalyBanner {
    /* width: 90vw !important;
    height: 100% !important;
    object-fit: scale-down !important; */
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .get2ItalyBannerWrapper img {
    object-fit: scale-down;
    /* Ensures placeholder matches the final image */
    width: 90vw !important;
    height: 100% !important;
    transition: all 0.3s ease-in-out;
    /* Smooth transition */
  }

  .get2ItalyBannerWrapper {
    overflow: hidden;
    /* Prevent any overflow issues */
    position: relative;
    width: 90vw !important;
    height: 100% !important;
    object-fit: scale-down !important;
  }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100% !important;

    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }

  .travelSolutionsContainer {
    padding: 10vh 5vw;
    text-align: center;
  }

  .HomeRegister {
    text-align: center;
  }

  .text-bestsolution h1 {
    font-size: 2rem !important;
  }

  .text-bestsolution p {
    font-size: 0.8rem !important;
  }
}

@media (min-width: 769px) and (max-width: 1199px) {
  .languageSwitcher {
    right: 5vw;
    bottom: 2vh;
  }

  .responsiveContainer992 {
    max-width: 80% !important;
  }

  .responsiveContainerWhite992 {
    width: 80% !important;
  }

  .para992 {
    font-size: 1rem !important;
  }

  .login-992 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .TopText {
    text-transform: uppercase;
    letter-spacing: 2px;
    /* font-size: 1.5rem !important; */
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    background-color: #d82a61;
    padding: 10px;
    color: #ffffff;
    background-color: #d82a61;
  }

  .bestSolutions {
    h5 {
      color: rgb(0, 0, 0);
      font-size: 1.4rem;
      font-family: Kanit;
      line-height: 2.6vw;
      font-weight: 400 !important;
      margin: 10px;
    }

    p {
      color: rgb(133, 133, 133);
      font-family: Kanit;
      line-height: 1;
      margin: 10px;
      font-size: 0.9rem;
    }
  }

  .languageSwitcherFab {
    right: 15vw;
    bottom: 2vh;
  }

  .loginContainer {
    max-width: 100%;
    display: flex;
    justify-content: space-around;
    background: rgb(249, 239, 244);
    padding: 1em 0em 1.5em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    align-items: center;
  }

  .pressenceContainer h5 {
    font-size: 5vw !important;
  }

  .products {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: 30px;
  }

  .get2ItalyBanner {
    /* width: auto !important;
    height: 100% !important; 
    object-fit: contain !important; */
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .get2ItalyBannerWrapper img {
    object-fit: scale-down;
    /* Ensures placeholder matches the final image */
    width: 90vw !important;
    height: 100% !important;
    transition: all 0.3s ease-in-out;
    /* Smooth transition */
  }

  .get2ItalyBannerWrapper {
    overflow: hidden;
    /* Prevent any overflow issues */
    position: relative;
    width: 90vw !important;
    height: 100% !important;
    object-fit: scale-down !important;
  }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100% !important;

    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }

  .travelSolutionsContainer {
    padding: 10vh 5vw;
    text-align: center;
  }

  .footer-content-992-2 {
    margin-left: 25px !important;
  }

  .footer-content-992-1 {
    margin-left: 15px !important;
  }

  .HomeRegister {
    text-align: center;
  }

  .HomeRegister p {
    font-size: 1rem;
  }

  .text-bestsolution h1 {
    font-size: 2rem !important;
  }

  .text-bestsolution p {
    font-size: 0.8rem !important;
  }

  .pressenceContainer h1 {
    font-size: 2rem !important;
  }

  .local-responsive-img {
    width: 12rem !important;
  }

  .footer-content-item p {
    font-size: 0.8rem !important;
  }

  .footer-content-item-img1 {
    width: 6vw !important;
  }

  .footer-content-item-img2 {
    width: 8vw !important;
  }
}

@media (min-width: 1010px) and (max-width: 1199px) {
  .languageSwitcher {
    right: 5vw;
    bottom: 2vh;
  }

  .TopText {
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    background-color: #d82a61;
    padding: 10px;
    color: #ffffff;
    background-color: #d82a61;
  }

  .bestSolutions {
    h5 {
      color: rgb(0, 0, 0);
      font-size: 1.4rem;
      font-family: Kanit;
      line-height: 2.6vw;
      font-weight: 400 !important;
      margin: 10px;
    }

    p {
      color: rgb(133, 133, 133);
      font-family: Kanit;
      line-height: 1;
      margin: 10px;
      font-size: 1rem;
    }
  }

  .languageSwitcherFab {
    right: 12vw;
    bottom: 2vh;
  }

  .loginContainer {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    background: rgb(249, 239, 244);
    padding: 1em 1em 1.5em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  }

  .pressenceContainer h5 {
    font-size: 3vw !important;
  }

  .products {
    display: flex;
    justify-content: space-between;
    padding: 1.5em 0 0;
  }

  .get2ItalyBanner {
    /* width: 50vw !important;
    height: 100% !important;
    
    object-fit: scale-down !important; */
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .get2ItalyBannerWrapper img {
    transition: all 0.3s ease-in-out;
    /* Smooth transition */
  }

  .get2ItalyBannerWrapper {
    overflow: hidden;
    /* Prevent any overflow issues */
    position: relative;
    width: 50vw !important;
    height: 100% !important;
    object-fit: scale-down !important;
  }

  .travelSolutionsContainer {
    padding: 10vh 5vw;
    text-align: center;
  }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100% !important;

    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }
}

@media (min-width: 1120px) and (max-width: 1800px) {
  .languageSwitcher {
    right: 5vw;
    bottom: 2vh;
  }

  .para1200 {
    font-size: 1.2rem !important;
  }

  .TopText {
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    color: #ffffff;
    text-align: center;
    background-color: #d82a61;
    padding: 10px;
    color: #ffffff;
    background-color: #d82a61;
  }

  .bestSolutions {
    h5 {
      color: rgb(0, 0, 0);
      font-size: 1.4rem;
      font-family: Kanit;
      line-height: 2.6vw;
      font-weight: 400 !important;
      margin: 10px;
    }

    p {
      color: rgb(133, 133, 133);
      font-family: Kanit;
      line-height: 1;
      margin: 10px;
      font-size: 1rem;
    }
  }

  .languageSwitcherFab {
    right: 10vw;
    bottom: 2vh;
  }

  .loginContainer {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    background: rgb(249, 239, 244);
    padding: 1em 1em 1.5em;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  }

  .pressenceContainer h5 {
    font-size: 3vw !important;
  }

  .products {
    display: flex;
    justify-content: space-between;
    padding: 1.5em 0 0;
  }

  .get2ItalyBanner {
    /* width: 47rem !important;
    height: 100% !important;
    object-fit: scale-down !important; */
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .get2ItalyBannerWrapper img {
    object-fit: scale-down;
    /* Ensures placeholder matches the final image */
    width: 50vw !important;
    height: 100% !important;
    transition: all 0.3s ease-in-out;
    /* Smooth transition */
  }

  .get2ItalyBannerWrapper {
    overflow: hidden;
    /* Prevent any overflow issues */
    position: relative;
    width: 50vw !important;
    height: 100% !important;
    object-fit: scale-down !important;
  }

  .travelSolutionsContainer {
    padding: 6rem 3rem;
    text-align: center;
  }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100% !important;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }
}

@media (min-width: 1801px) {
  .languageSwitcher {
    right: 1vw;
    bottom: 2vh;
  }

  .TopText {
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    background-color: #d82a61;
    padding: 10px;
    color: #ffffff;
    background-color: #d82a61;
  }

  .bestSolutions {
    h5 {
      color: rgb(0, 0, 0);
      font-size: 1.4rem;
      font-family: Kanit;
      line-height: 2.6vw;
      font-weight: 400 !important;
      margin: 2.5vh 10px;
    }

    p {
      color: rgb(133, 133, 133);
      font-family: Kanit;
      line-height: 1;
      margin: 10px;
      font-size: 1rem;
    }
  }

  .languageSwitcherFab {
    right: 4vw;
    bottom: 2vh;
  }

  .loginContainer {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    background: rgb(249, 239, 244);
    padding: 1em 1em 1.5em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  }

  .pressenceContainer h5 {
    font-size: 3vw !important;
  }

  .products {
    display: flex;
    justify-content: space-between;
    padding: 1.5em 0 0;
  }

  .get2ItalyBanner {
    /* width: 50vw !important;
    height: 100% !important;
    object-fit: scale-down !important; */
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .get2ItalyBannerWrapper img {
    object-fit: scale-down;
    /* Ensures placeholder matches the final image */
    width: 50vw !important;
    height: 100% !important;
    transition: all 0.3s ease-in-out;
    /* Smooth transition */
  }

  .get2ItalyBannerWrapper {
    overflow: hidden;
    /* Prevent any overflow issues */
    position: relative;
    width: 50vw !important;
    height: 100% !important;
    object-fit: scale-down !important;
  }

  .travelSolutionsContainer {
    padding: 10vh 5vw;
    text-align: center;
  }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100% !important;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }
}

/*responsive css end  */

.flight-itenary-details-item-text {
  font-size: 18px;
  font-weight: 500;
}

.flight-itenary-details-item-text
  .css-1qqsdnr-MuiAutocomplete-root
  .MuiFilledInput-root.MuiInputBase-sizeSmall {
  padding: 0px !important;
  min-width: 150px;
}

.vb-highlight-txt {
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  color: #9f155a !important;
}

.itenary-confirmtaion-tag {
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Ticketed {
  background-color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Refunded,
.Void_Refunded,
.ReIssued {
  background-color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Exchanged {
  background-color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Payment_Success {
  background-color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Booked,
.Voided {
  background-color: #fd7e14;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Booking_Cancelled {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Hold_Cancelled,
.Hold_Cancelled_Failed {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Rejected,
.Cancel_Rejected {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Cancelled {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Booking_Rejected {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Booking_Failed,
.Booking_On_Hold_Failed {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Cancel_Requested,
.Void_Requested,
.ReIssue_Requested {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Refund_Initiated,
.Void_RefundInitiated,
.Void_Initiated {
  background-color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Ticket_Failed {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Payment_Failed {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Booking_Cancellation_Failed,
.Booking_Cancelling_In_Process {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Void_Failed,
.Void_Rejected,
.ReIssue_Rejected {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Refund_Failed {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Booking_On_Hold,
.Hold_Booking_Ticket_Pending {
  background-color: #fd7e14;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Ticketing_In_Process,
.Booking_Pending,
.ReIssue_Pending,
.Void_In_Process,
.Booking_In_Process,
.Payment_In_Process,
.Refund_In_Process,
.Exchange_In_Process,
.Void_Queued {
  background-color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Ticketing_Pending {
  background-color: #f76218e0;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.flight-itenary {
  background-color: #ffffff;
  border-radius: 14px;
  margin-top: 24px;
}

.viewBooking .flight-itenary {
  background-color: #ffffff;
  border-radius: 14px;
  margin-top: 24px;
}

.viewBooking .flight-itenary-title-container {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  font-family: "Cabin";
}

.viewBooking .flight-itenary-date {
  font-size: 16px;
  font-weight: 500;
}

.viewBooking .flight-itenary-icon {
  opacity: 0.4;
  margin-left: 6px;
  margin-right: 6px;
  height: 24px;
  width: 24px;
}

.viewBooking .flight-itenary-details {
  padding: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.viewBooking .flight-itenary-details-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 100px;
}

.viewBooking .flight-itenary-details-item-text {
  font-size: 18px;
  font-weight: 500;
}

.viewBooking .flight-itenary-details-item-subtext {
  font-size: 12px;
  font-weight: 300;
  /* opacity: 0.4; */
  margin-top: 4px;
}

.viewBooking .flight-confirmation-details {
  margin: 16px 24px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

.viewBooking .flight-confirmation-details-item-text {
  font-size: 13px;
  font-weight: 500;
}

.viewBooking .flight-confirmation-details-item-subtext {
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  opacity: 0.6;
}

.viewBooking .flight-confirmation-details-item {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.full-opacity {
  opacity: 1 !important;
}

.viewBooking .itenary-trip-details {
  flex-direction: column;
  margin: 16px 24px;
  padding-bottom: 8px;
  display: flex;
}

.viewBooking .itenary-flight-details {
  flex: 1;
}

.itenary-trip-from-to-container {
  flex: 1;
  display: flex;
}

.font-size-14 {
  font-size: 14px !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.viewBooking .flight-itenary-to-container {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.5;
}

.itenary-to-line {
  flex: 1;
  height: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.itenary-to-text {
  margin-left: 16px;
  margin-right: 16px;
  font-size: 12px !important;
  opacity: 0.6;
  font-weight: 500;
}

.itenary-passenger-details {
  width: 100%;
  margin-top: 24px;
}

.viewBooking .itenary-passenger-table {
  width: 100%;
  border-spacing: 1px;
  text-align: left !important;
}

.viewBooking .itenary-passenger-table-header {
  height: 48px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #340f34;
  color: #ffffff;
}

.header-item-font-weight {
  font-weight: 400;
}

.viewBooking .itenary-passenger-table-header-pl-12 {
  padding-left: 12px;
}

.viewBooking .itenary-passenger-table-header-pl-30 {
  padding-left: 30px;
}

.viewBooking .itenary-passenger-table-header-item-initial {
  border-top-left-radius: 8px;
  /* border-bottom-left-radius: 8px; */
}

.viewBooking .itenary-passenger-table-header-item-final {
  border-top-right-radius: 8px;
  /* border-bottom-right-radius: 8px; */
}

.viewBooking .itenary-passenger-table-item-initial {
  /* border-top-left-radius: 8px; */
  border-bottom-left-radius: 8px;
}

.viewBooking .itenary-passenger-table-item-final {
  /* border-top-right-radius: 8px; */
  border-bottom-right-radius: 8px;
}

.viewBooking .itenary-passenger-table-item {
  background-color: #ffff;
  color: #000000;
  /* border-radius: 8px; */
}

.viewBooking .itenary-passenger-table-h-64 {
  height: 48px;
  line-height: 4.7vh;
  color: #2c2c2ce8;
  padding: 10px;
}

.width25 {
  width: 25% !important;
}

.width20 {
  width: 20% !important;
}

.width15 {
  width: 15% !important;
}

.width10 {
  width: 10% !important;
}

.textCenter {
  text-align: center !important;
}

.viewBooking .itenary-passenger-table-h-64 span {
  opacity: 1;
}

.viewBooking .itenary-passenger-table-header .header-item {
  color: #ffffff !important;
}

.viewBooking .MuiTableCell-head {
  font-weight: bold !important;
  font-size: 13px !important;
  opacity: 1 !important;
  /* text-align: center !important; */
}

.viewBooking .fareDetailsTable th {
  text-align: center !important;
}

.viewBooking .fareDetailsTable td {
  text-align: center !important;
}

.flightItineraryColumnHeader {
  text-align: center !important;
  /* flex: 1 1 auto !important;
	max-width: 20% !important;
	height: auto !important;
	white-space: normal !important;
	overflow-wrap: break-word !important;
	word-break: break-word !important; */
}

.flightItineraryColumn {
  /* flex: 1 1 auto !important;
	max-width: 20% !important;
	height: auto !important;
	white-space: normal !important;
	overflow-wrap: break-word !important;
	word-break: break-word !important;
	min-width: 10% !important; */
  text-align: center !important;
}

.itenary-container {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 25px !important;
  color: #340f2f;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addButton {
  color: #9f155a !important;
  border: 1px solid rgba(159, 21, 90, 0.5) !important;
  text-transform: capitalize !important;
}

.addButton:disabled {
  color: #0000001f !important;
  border: 1px solid #0000001f !important;
  text-transform: capitalize !important;
}

.itenary-container-header {
  align-items: center;
  border-bottom: 1px solid #0000001a;
  color: #340f2f;
  font-family: Cabin;
  font-size: 25px !important;
  font-weight: 700;
  justify-content: space-between;
  padding-bottom: 1%;
}

.disabled-btn {
  opacity: 0.1;
  cursor: not-allowed;
  background-color: #dcdcdc;
  color: #888888;
  border: 1px solid #cccccc;
}
