/* @import './components/Administration/Administration.css';
@import './components/PreferenceToggleButtons/PreferenceToggleButton.css';
@import './components/ViewBooking/ViewBooking.css';
@import './components/SearchResultsPageNew/searchResults.css';
@import './components/Header/Header.css';
@import './components/Footer/Footer.css';
@import './components/LandingPage/LandingPage.css';
@import './components/ReviewBooking/ReviewBooking.css';
@import './components/Configuration/Configuration.css';
@import './components/ManageProfile/ManageProfile.css';
@import './components/MyQueue/QueueTable/QueueTable.css';
@import './components/ClientOnBoarding/ClientOnBoarding.css';
@import './components/Transactions/DebitTransactions.css';
@import './components/ClientRoleRights/ClientRoleRights.css';
@import './components/Configuration/Vendor/Vendor.css';
@import './components/Configuration/Supplier/Supplier.css';
@import './components/Configuration/MarkupGroup/MarkupGroup.css';
@import './components/Configuration/SupplierCommissionGroup/SupplierCommissionGroup.css';
@import './components/Configuration/BookingSwitches/BookingSwitches.css';
@import './components/ManageClinets/ManageClients.css';
@import './components/ManageUsers/RegisterUser/RegisterUser.css';
@import './components/ManageUsers/ManageUser.css';
@import './components/Configuration/SearchCategory/SearchCategory.css';
@import './components/Travel Schedule/TravelSchedule.css';
@import './components/Payment/autoImps.css';
@import './components/Configuration/PassthroughConfiguration/PassthroughConfiguration.css';
@import './components/OfflineBooking/OfflineBooking.css';
@import './components/ImportBooking/ImportBooking.css';
@import './components/Dashboard/Dashboard.css';
@import './components/Configuration/FlightSearchHistory/FSH.css';
@import './components/NotFound/NotFound.css';
@import './providers/UnauthorizedTemplate/Unauthorizedtemplate.css';
@import './components/Configuration/MarkupBlock/MarkupBlock.css';
@import './components/RoleRights/RoleRights.css';
@import './components/Receipt/Receipt.css';
@import './components/Invoice/Invoice.css';
@import './components/BankInfo/BankInfo.css';
@import './components/BankInfo/BankInfoTable/BankInfoTable.css';
@import './components/Configuration/SearchCategoryConfiguration/SearchCategoryConfiguration.css';
@import './components/PassengerProfile/PassengerProfile.css';
@import './components/Operating OND/OperatingOND.css';
@import './components/ClientBalanceReport/ClientBalanceReport.css';
@import './components/ManageResetPassword/ResetPassword.css';
@import './components/Configuration/TicketingConfiguration/TicketingConfiguration.css';
@import './components/BankInfo/AddBankInfoCard/AddBankInfoCard.css';
@import './components/BankInfo/BankInfoDrawer/BankInfoDrawer.css';
@import './components/BasicSearchBar/BasicSearchBar.css';
@import './components/ClassSelectMenu/ClassSelectMenu.css';
@import './components/ClientAlerts/ClientAlert.css';
@import './components/ClientOnBoarding/DocumentUpload/DocumentUpload.css';
@import './components/ClientRoleRights/ClientRoleDrawer/ClientRoleDrawer.css';
@import './components/ClientRoleRights/ClientRoleRights.css';
@import './components/Configuration/AddConfigurationCard/AddConfigurationCard.css';
@import './components/Configuration/AgentMarkup/AgentMarkup.css';
@import './components/Configuration/BookingSwitches/AddBookingSwitchesCard/AddBookingSwitchesCard.css';
@import './components/Configuration/BookingSwitches/BookingSwitchesDrawer/BookingSwitchesDrawer.css';
@import './components/Configuration/BookingSwitches/BookingSwitchesTable/BookingSwitchesTable.css';
@import './components/Configuration/BookingSwitches/BookingSwitches.css';
@import './components/Configuration/CategoryCard/CategoryCard.css';
@import './components/Configuration/CategoryDrawer/CategoryDrawer.css';
@import './components/Configuration/ConfigurationDrawer/ConfigurationDrawer.css';
@import './components/Configuration/ConfigurationTable/ConfigurationTable.css';
@import './components/Configuration/FlightSearchHistory/FSHDetails/FSHDetails.css';
@import './components/Configuration/FlightSearchHistory/Search/FSHSearch.css';
@import './components/Configuration/FlightSearchHistory/FSH.css';
@import './components/Configuration/MarkupBlock/AddMarkupBlockCard/AddMarkupBlockCard.css';
@import './components/Configuration/MarkupBlock/MarkupBlockDrawer/MarkupBlockDrawer.css';
@import './components/Configuration/MarkupBlock/MarkupBlockTable/MarkupBlockTable.css';
@import './components/Configuration/MarkupBlock/MarkupBlock.css';
@import './components/Configuration/MarkupGroup/AddMarkupGroupCard/AddMarkupGroupCard.css';
@import './components/Configuration/MarkupGroup/MarkupGroupDrawer/MarkupGroupDrawer.css';
@import './components/Configuration/MarkupGroup/MarkupGroupTable/MarkupGroupTable.css';
@import './components/Configuration/MarkupGroup/MarkupGroup.css';
@import './components/Configuration/PassthroughConfiguration/PassthroughConfiguration.css';
@import './components/Configuration/PassthroughConfiguration/PassthroughConfigurationTable.css';
@import './components/Configuration/Reconcilliation/RecoDetails/RecoDetails.css';
@import './components/Configuration/Reconcilliation/Reco.css';
@import './components/Configuration/SearchCategory/AddSearchCategoryCard/AddSearchCategoryCard.css';
@import './components/Configuration/SearchCategory/SearchCategoryDrawer/SearchCategoryDrawer.css';
@import './components/Configuration/SearchCategory/SearchCategoryTable/SearchCategoryTable.css';
@import './components/Configuration/SearchCategory/SearchCategory.css';
@import './components/Configuration/SearchCategoryConfiguration/AddSearchCategoryConfigurationCard/AddSearchCategoryConfigurationCard.css';
@import './components/Configuration/SearchCategoryConfiguration/SearchCategoryConfigurationDrawer/SearchCategoryConfigurationDrawer.css';
@import './components/Configuration/SearchCategoryConfiguration/SearchCategoryConfigurationTable/SearchCategoryConfigurationTable.css';
@import './components/Configuration/SearchCategoryConfiguration/SearchCategoryConfiguration.css';
@import './components/Configuration/Supplier/AddSupplierCard/AddSupplierCard.css';
@import './components/Configuration/Supplier/SupplierDrawer/SupplierDrawer.css';
@import './components/Configuration/Supplier/SupplierTable/SupplierTable.css';
@import './components/Configuration/Supplier/Supplier.css';
@import './components/Configuration/SupplierCommissionBlock/AddSupplierCommissionBlockCard/AddSupplierCommissionBlockCard.css';
@import './components/Configuration/SupplierCommissionBlock/SupplierCommissionBlockDrawer/SupplierCommissionBlockDrawer.css';
@import './components/Configuration/SupplierCommissionBlock/SupplierCommissionBlockTable/SupplierCommissionBlockTable.css';
@import './components/Configuration/SupplierCommissionBlock/SupplierCommissionBlock.css';
@import './components/Configuration/SupplierCommissionGroup/AddSupplierCommissionGroupCard/AddSupplierCommissionGroupCard.css';
@import './components/Configuration/SupplierCommissionGroup/SupplierCommissionGroupDrawer/SupplierCommissionGroupDrawer.css';
@import './components/Configuration/SupplierCommissionGroup/SupplierCommissionGroupTable/SupplierCommissionGroupTable.css';
@import './components/Configuration/SupplierCommissionGroup/SupplierCommissionGroup.css';
@import './components/Configuration/TicketingConfiguration/TicketingConfiguration.css';
@import './components/Configuration/TicketingConfiguration/TicketingConfigurationTable.css';
@import './components/Configuration/Vendor/AddVendorCard/AddVendorCard.css';
@import './components/Configuration/Vendor/VendorDrawer/VendorDrawer.css';
@import './components/Configuration/Vendor/VendorTable/VendorTable.css';
@import './components/Configuration/Vendor/ViewVendor/ViewVendor.css';
@import './components/Configuration/Vendor/Vendor.css';
@import './components/Configuration/Configuration.css';
@import './components/Dashboard/Dashboard.css';
@import './components/DatePicker/DatePicker.css';
@import './components/DestinationSearchMenu/DestinationSearchMenu.css';
@import './components/Eticket/Eticket.css';
@import './components/Eticket/Results.css';
@import './components/Footer/Footer.css';
@import './components/Header/Header.css';
@import './components/ImportBooking/FlightImportBooking/FlightBaggageDetails/FlightBaggageDetail.css';
@import './components/ImportBooking/SearchImportBookingCard/SearchImportBookingCard.css';
@import './components/ImportBooking/addPassengerDrawer.css';
@import './components/ImportBooking/ImportBooking.css';
@import './components/ImportBookingNew/ChangeSupplierCard/ChangeSupplierCard.css';
@import './components/ImportBookingNew/ImportBookingNew.css';
@import './components/ImportBookingNew/ImportBookingNew.css';
@import './components/ImportPNR/ImportPNR.css';
@import './components/ImportPNR/PricingOptions.css';
@import './components/Invoice/Invoice.css';
@import './components/LandingPage/LandingPage.css';
@import './components/Login/Otp/Otp.css';
@import './components/Login/Signin.css';
@import './components/Login/SignInHead.css';
@import './components/ManageClinets/AddClientCard/AddClientCard.css';
@import './components/ManageClinets/ClientDrawer/ClientDrawer.css';
@import './components/ManageClinets/ClientDrawer/ClientDrawer.css';
@import './components/ManageClinets/ClientPayment/ClientPayment.css';
@import './components/ManageClinets/ClientTable/ClientTable.css';
@import './components/ManageClinets/RegisterClient/RegisterClient.css';
@import './components/ManageClinets/RegisterClientUser/RegisterClientUser.css';
@import './components/ManageClinets/ViewClient/ViewClient.css';
@import './components/ManageClinets/ManageClients.css';
@import './components/ManageProfile/ManageProfile.css';
@import './components/ManageResetPassword/ResetPassword.css';
@import './components/ManageUsers/AddUser/AddUser.css';
@import './components/ManageUsers/RegisterUser/RegisterUser.css';
@import './components/ManageUsers/SearchUser/SearchUser.css';
@import './components/ManageUsers/UserDrawer/UserDrawer.css';
@import './components/ManageUsers/UserTable/UserTable.css';
@import './components/ManageUsers/ManageUser.css';
@import './components/ModifySearchBar/ModifySearchBar.css';
@import './components/MulticitySearchBar/MulticitySearchBar.css';
@import './components/MyQueue/Facets/CheckboxFacet/CheckboxFacet.css';
@import './components/MyQueue/Facets/Facets.css';
@import './components/MyQueue/MyQueueDrawer/MyQueueDrawer.css';
@import './components/MyQueue/MyQueueTable/MyQueueTable.css';
@import './components/MyQueue/Pager/Pager.css';
@import './components/MyQueue/QueueTable/QueueTable.css';
@import './components/MyQueue/Results/Result/Result.css';
@import './components/MyQueue/Results/Results.css';
@import './components/MyQueue/SearchBar/Suggestions/Suggestions.css';
@import './components/MyQueue/SearchBar/SearchBar.css';
@import './components/MyQueue/MyQueue.css';
@import './components/NotFound/NotFound.css';
@import './components/Notifications/Notifications.css';
@import './components/OfflineBooking/addPassengerDrawer.css';
@import './components/OfflineBooking/OfflineBooking.css';
@import './components/OfflineBooking/PreviewOfflineBooking.css';
@import './components/Operating OND/OperatingOND.css';
@import './components/PassengerProfile/PassengerProfile.css';
@import './components/Payment/autoImps.css';
@import './components/Payment/Payment.css';
@import './components/Payment/PaymentHisotry.css';
@import './components/Payment/PaymentType.css';
@import './components/Payment/PaymentType.css';
@import './components/PendingApprovalQueue/PendingQueueTable/PendingQueueTable.css';
@import './components/PendingApprovalQueue/PendingApprovalQueue.css';
@import './components/PeopleClassMenu/PeopleClassMenu.css';
@import './components/Receipt/AddReceiptCard/AddReceiptCard.css';
@import './components/Receipt/ReceiptDrawer/ReceiptDrawer.css';
@import './components/Receipt/ReceiptTable/ReceiptTable.css';
@import './components/Receipt/Receipt.css';
@import './components/ReviewBooking/CCpayment.css';
@import './components/ReviewBooking/CCpayment.css';
@import './components/ReviewBooking/HoldRulesDrawer.css';
@import './components/ReviewBooking/ReviewBooking.css';
@import './components/ReviewBooking/ReviewBooking.css';
@import './components/RoleRights/RoleDrawer/RoleDrawer.css';
@import './components/RoleRights/RoleRights.css';
@import './components/SearchBar/Searchbar.css';
@import './components/SearchResultsPage/FilterComponents/FilterComponents.css';
@import './components/SearchResultsPage/Filters/Filters.css';
@import './components/SearchResultsPage/ModifySearchDialog/ModifySearchDialog.css';
@import './components/SearchResultsPage/Results/Results.css';
@import './components/SearchResultsPage/SortComponents/SortComponent.css';
@import './components/SearchResultsPage/TicketDrawer/FlightDetails/FlightDetails.css';
@import './components/SearchResultsPage/TicketDrawer/TicketDrawer.css';
@import './components/SearchResultsPage/TicketSelection/TicketSelection.css';
@import './components/SearchResultsPage/TripSelection/TripSelection.css';
@import './components/SearchResultsPage/SearchResultsPage.css';
@import './components/SearchResultsPageNew/Filters/Filters.css';
@import './components/SearchResultsPageNew/SortComponents/SortComponent.css';
@import './components/SearchResultsPageNew/PrevNextComponent.css';
@import './components/SearchResultsPageNew/FilterComponents/FilterComponents.css';
@import './components/ToolTip/ToolTip.css';
@import './components/Transactions/DebitTransactions.css';
@import './components/Travel Schedule/TravelSchedule.css';
@import './components/TripTypeRadioButtons/TripTypeRadioButtons.css';
@import './components/ViewBooking/Void/Void.css';
@import './components/ViewCancellation/ViewCancellation.css';
@import './components/Void/VoidRulesConfiguration/VoidRulesConfiguration.css';
@import './components/Void/AddVoidRulesDrawer.css';
@import './components/Void/VoidAdmin.css';
@import './components/Void/VoidQuote.css';
@import './components/WalletTopUp/RechargeTransactions.css';
@import './components/WalletTopUp/TopUp.css';
@import './providers/TravelFallbackUI/TravelFallbackUI.css';
@import './providers/UnauthorizedTemplate/Unauthorizedtemplate.css'; */

.MuiTabs-scroller .active {
    color: #9f155a !important;
    opacity: 1 !important;
  }
  
  .makeStyles-tab-22 {
    opacity: 1 !important;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #ffb703;
  }
  
  .searchScroll {
    overflow-y: scroll !important;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  .sessionTimer {
    text-transform: capitalize;
    color: red;
    font-size: 40px !important;
  }
  
  .textCenter {
    text-align: center !important;
  }
  
  .textEnd {
    text-align: end !important;
  }
  
  .sessionTimerred {
    text-transform: capitalize;
    font-size: 40px !important;
    color: red !important;
    animation: 2s anim-popoutin ease infinite;
  }
  
  /* @keyframes anim-popoutin {
    0% {
      color: black;
      transform: scale(0);
      opacity: 0;
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    25% {
      color: red;
      transform: scale(1);
      opacity: 1;
      text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
    }
    50% {
      color: black;
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
  } */
  .sessionTimergreen {
    text-transform: capitalize;
    font-size: 40px !important;
    color: green !important;
    transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  }
  
  @keyframes scale {
    100% {
      transform: scale(1);
    }
  }
  
  .sessionActionButtons {
    flex: 0 0 auto;
    display: flex;
    padding: 8px;
    align-items: center !important;
    justify-content: center !important;
  }
  
  .posFix {
    position: fixed !important;
  }
  
  .width100 {
    width: 100% !important;
  }
  
  .detailedFareButton {
    margin-left: 25% !important;
    color: #9f155a !important;
  }
  
  .detailedFareCloseButton {
    color: #9f155a !important;
    float: right !important;
  }
  
  .detailedFareDiv {
    min-width: 65vw !important;
    /* max-height: 600px !important; */
    /* margin-top: 100px !important; */
  }
  
  .detailedFare .MuiTabs-centered {
    background-color: #fff !important;
    color: #9f155a !important;
  }
  
  .detailedFare .MuiTab-root {
    text-transform: capitalize !important;
  }
  
  .detailedFare .Mui-selected {
    background-color: #9f155a !important;
    color: #fff !important;
  }
  
  .detailedFare .MuiTabs-indicator {
    background-color: #fff !important;
  }
  
  .detailedFare .MuiTableCell-root {
    max-width: 300px !important;
  }
  
  .detailedFare .mealTotal .MuiTableCell-root {
    font-weight: 600 !important;
  }
  
  .MuiButton-containedSecondary {
    background-color: #9f155a !important;
    color: #fff !important;
  }
  
  .ticketModalContainer {
    max-height: 550px !important;
    overflow-y: auto !important;
  }
  
  .ticketModalContainer .MuiFormLabel-root.Mui-disabled {
    color: #000 !important;
  }
  
  .bdr1dot {
    border-right: 1px dotted;
  }
  
  .textTransCap {
    text-transform: capitalize !important;
  }
  
  .textElipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  
  .bagText {
    max-width: 100px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: clip !important;
    font-size: 14px !important;
    margin-bottom: 0.5rem !important;
    line-height: 1.2 !important;
  }
  
  .color0 {
    color: #000 !important;
  }
  
  .colorRed {
    color: red !important;
  }
  
  .colorGreen {
    color: green !important;
  }
  
  .colorBlack {
    color: #000 !important;
  }
  
  .backddd47 {
    background: #dddddd47 !important;
  }
  
  .backddd82 {
    background: #dddddd82 !important;
  }
  
  .opacity1 {
    opacity: 1 !important;
  }
  
  .marginLR15 {
    margin: 0px 15% !important;
  }
  
  .currencySymbol {
    padding: 0px 5px !important;
    float: left !important;
  }
  
  .mr10 {
    margin-right: 10px !important;
  }
  
  .fs10 {
    font-size: 10px !important;
  }
  
  .fs12 {
    font-size: 12px !important;
  }
  
  .fs14 {
    font-size: 14px !important;
  }
  
  .fs16 {
    font-size: 16px !important;
  }
  
  .m0 {
    margin: 0px !important;
  }
  
  .linkcolor {
    /* color: #0d6efd !important;
    color: #a0155a !important; */
    color: #db2a5f !important;
    cursor: pointer !important;
  }
  
  .G2IRetailQR {
    width: 5vw !important;
    object-fit: scale-down !important;
  }
  
  .pad1vw {
    padding: 1vw !important;
  }
  
  .padLR2vw {
    padding: 0 2vw;
  }
  
  .padTB1vw {
    padding-top: 1vw;
    padding-bottom: 1vw;
  }
  
  .padLR1vw {
    padding: 0 1vw;
  }
  
  .padR2vw {
    padding-right: 2vw;
  }
  
  .fontMono {
    font-family: monospace !important;
  }
  
  .fontSansSerif {
    font-family: sans-serif !important;
  }
  
  .fontCabin {
    font-family: "Cabin" !important;
  }
  
  .fontCabinBold {
    font-family: "CabinBold" !important;
  }
  
  .floatInEnd {
    float: inline-end !important;
  }
  
  .fw500 {
    font-weight: 500 !important;
  }
  
  .fw600 {
    font-weight: 600 !important;
  }
  
  .Failure {
    color: red !important;
  }
  
  .Aborted {
    color: red !important;
  }
  
  .Invalid {
    color: red !important;
  }
  
  .Timeout {
    color: red !important;
  }
  
  .Success {
    color: green !important;
  }
  
  .Pending {
    color: orange !important;
  }
  
  .walletTopupAmountText .currencySymbol {
    padding: 0px 5px !important;
    float: none !important;
  }
  
  .floatL {
    float: left !important;
  }
  
  .orderTracePicker {
    max-width: 20vw !important;
  }
  
  .col-4 {
    flex: 0 0 auto !important;
    width: 33.33333333% !important;
  }
  
  .dispFlex {
    display: flex;
  }
  
  .alignitemsCenter {
    align-items: center;
  }
  
  .parseItineraryBtn {
    float: left !important;
    margin-left: 10px !important;
    color: #0a3622 !important;
    border-color: #0a3622 !important;
  }
  
  .parseItineraryBtn:disabled {
    float: left !important;
    margin-left: 10px !important;
    color: #787878 !important;
    border-color: #787878 !important;
  }
  
  .pg-charge-bold .currencySymbol {
    float: none !important;
  }
  
  .MuiAccordionSummary-root {
    min-width: 100% !important;
  }
  
  .MuiAccordionSummary-gutters {
    min-width: 100% !important;
  }
  
  .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
    min-width: 100% !important;
  }
  
  .MuiButtonBase-root .MuiAccordionSummary-root .MuiAccordionSummary-gutters .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
    min-width: 100% !important;
  }
  
  .custom-editor {
    width: 600px;
    height: 300px;
    overflow: auto;
    background-color: #0D0D0D;
    color: #02E3AB;
    font-size: 0.9rem;
    padding: 1rem;
  }
  
  .custom-editor p,
  .custom-editor code {
    color: #02E3AB;
    font-size: 0.9rem;
  }
  
  
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 20px !important;
    height: 20px !important;
    padding: 0;
    cursor: pointer;
    background-color: rgba(165, 26, 91, 0.46) !important;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
  }
  
  .carousel-indicators {
    justify-content: flex-start !important;
    padding: 0 !important;
    margin: 0 !important;
    margin-left: 5% !important;
  }
  
  .carousel-indicators .active {
    background-color: rgba(165, 26, 91, 1) !important;
  }
  
  .travelSolutionsContainer {
    background-color: rgba(159, 21, 90, 0.05);
    font-size: 2vw;
  }
  
  .pressenceContainer {    
    padding: 4rem 0;
    background: white;
  }
  .trans-client-container {
    display: flex;
    justify-content: center;
  }
  
  .trans-data {
    margin-top: 30px;
  }
  
  .trans-client-container-search-button {
    background-color: #9f155a !important;
    color: white !important;
    padding: 15px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    cursor: pointer !important;
    display: flex !important;
    border: 0px;
  }
  
  .trans-client-container-search-button:disabled {
    background-color: #ddd !important;
    color: #00000052 !important;
  }
  
  .trans-client-container-export-button {
    background-color: #9f155a !important;
    color: white !important;
    padding: 15px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    cursor: pointer !important;
    display: flex !important;
    border-left: 5px solid #fff;
  }
  
  .trans-client-container-export-button.disabled {
    background-color: #b7afaf !important;
    color: #472a2a !important;
    cursor: not-allowed !important;
  }
  
  .trans-client-container-item2 {
    background-color: white;
    border: 1px solid #d4d4d4;
  }
  
  .trans-client-container-label {
    background-color: gainsboro;
    padding: 15px;
    border: 1px solid #d4d4d4;
  }
  
  .trans-client-container-labelLeft {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .loader-box-content {
      background-color: #ffffff;
      padding: 15px 35px;
      border-radius: 8px;
      text-align: center;
  }
  .flt-main-heading {
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      color: #6a0537;
  }
  .flight-itenary-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: #340f34;
    color: #ffffff;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
  }
  table.fareDetailsTable {
      width: 100%;
      text-align: center;
  }
  
  table.fareDetailsTable th,
  table.fareDetailsTable td {
      border: 0.5px solid #3c393938 !important;
  }
  
  table.fareDetailsTable th,
  table.fareDetailsTable .main-heading {
      font-size: 14px;
      color: #340f34;
      font-weight: 500;
      padding: 5px;
      background-color: #eeeeee;
  }
  
  table.fareDetailsTable td {
      font-size: 13px;
      color: #000000;
      font-weight: 500;
      padding: 5px;
      border: 0.5px solid #3c393938 !important;
  }
  .more-detail-item {
    display: flex;
  }
  
  .more-detail-item-icon {
    padding: 12px;
    background-color: #ffffff;
    margin-right: 16px;
    border-radius: 4px;
    max-height: 24px;
  }
  
  .more-detail-item-description {
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5;
    text-align: justify;
  }
  .create-supplier-contents {
    flex: 0.7;
  }
  .create-supplier-contents-container {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
  }
  .add-supplier-if-items-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .search-view-edit-drawer-input-container {
    display: flex;
    width: 100%;
    margin-top: 24px;
  }  

 