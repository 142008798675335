.destination-search-menu-container {
  min-width: 360px;
  min-height: 360px;
  max-height: 360px;
  overflow: scroll;
}

.destination-search-menu-container {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.destination-search-menu-container::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.destination-search-bar {
  height: 50px;
  margin-top: -8px;
  width: 100%;
  box-shadow: 0px 3px 7px 0px #0000000d;
  display: flex;
  position: fixed;
  z-index: 100;
  position: absolute;
  background: #ffffff;
}

.input-base-container {
  width: 100%;
  margin-top: 4px;
  margin-left: 8px;
}

.search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-left: 16px;
  opacity: 0.4;
}

.destination-list-item {
  height: 45px;
  display: flex;
  padding: 3px 16px;
}

.destination-details {
  flex: 0.8;
  display: flex;
  flex-direction: column;
}

.airport-place {
  font-family: "Cabin";
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.airport-name {
  font-family: "Cabin";
  font-size: 12px;
  opacity: 0.5;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.destination-code {
  font-family: "Cabin";
  font-size: 13px;

  font-weight: bold;
  text-transform: capitalize;
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.destination-list-view {
  margin-top: 50px;
}

.bb-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pointer {
  cursor: pointer;
}

.no-results-found {
  margin-top: 64px;
  width: 100%;
  text-align: center;
  font-family: "Cabin";
  font-weight: bold;
  font-size: 13px;
}

input,
textarea,
select {
  font-family: "Cabin";
}